<template>
  <div class="px-5 py-5 ">
    <h1 class="title">Nail Psoriasis Severity Index (NAPSI) MM</h1>

    <h1 class="title has-text-centered">UÑA DEDOS DE LAS MANOS</h1>

    <div class="is-centered">
      <div class="table-container">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th></th>

              <th v-for="(item, index) in 10" :key="index">
                <b-tag type="is-dark" rounded class="ml-5">{{ item }}</b-tag>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p class="pr-5">Onicolisis y discromia</p>
                <p>en gota de aceite</p>
              </td>
              <td></td>

              <td>
                <b-field>
                  <b-select
                    v-model="finger1Onicolisis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger1OnicolisisAnswers"
                      :key="index"
                      :value="item[1]"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger2Onicolisis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger2OnicolisisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger3Onicolisis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger3OnicolisisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger4Onicolisis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger4OnicolisisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger5Onicolisis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger5OnicolisisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger6Onicolisis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger6OnicolisisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger7Onicolisis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger7OnicolisisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger8Onicolisis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger8OnicolisisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger9Onicolisis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger9OnicolisisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger10Onicolisis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger10OnicolisisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
            </tr>
            <tr>
              <td>Pitts</td>
              <td></td>

              <td>
                <b-field>
                  <b-select
                    v-model="finger1Pitts"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger1PittsAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger2Pitts"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger2PittsAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger3Pitts"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger3PittsAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger4Pitts"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger4PittsAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger5Pitts"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger5PittsAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger6Pitts"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger6PittsAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger7Pitts"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger7PittsAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger8Pitts"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger8PittsAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger9Pitts"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger9PittsAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger10Pitts"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger10PittsAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
            </tr>
            <tr>
              <td>Onicodistrofia</td>
              <td></td>

              <td>
                <b-field>
                  <b-select
                    v-model="finger1Onicodistrofia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger1OnicodistrofiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger2Onicodistrofia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger2OnicodistrofiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger3Onicodistrofia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger3OnicodistrofiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger4Onicodistrofia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger4OnicodistrofiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger5Onicodistrofia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger5OnicodistrofiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger6Onicodistrofia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger6OnicodistrofiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger7Onicodistrofia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger7OnicodistrofiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger8Onicodistrofia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger8OnicodistrofiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger9Onicodistrofia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger9OnicodistrofiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger10Onicodistrofia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger10OnicodistrofiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
            </tr>

            <tr>
              <td>Leuconiquia</td>
              <td></td>

              <td>
                <b-field>
                  <b-select
                    v-model="finger1Leuconiquia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger1LeuconiquiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger2Leuconiquia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger2LeuconiquiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger3Leuconiquia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger3LeuconiquiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger4Leuconiquia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger4LeuconiquiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger5Leuconiquia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger5LeuconiquiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger6Leuconiquia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger6LeuconiquiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger7Leuconiquia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger7LeuconiquiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger8Leuconiquia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger8LeuconiquiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger9Leuconiquia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger9LeuconiquiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger10Leuconiquia"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger10LeuconiquiaAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
            </tr>

            <tr>
              <td>Hemorragias en astilla</td>
              <td></td>

              <td>
                <b-field>
                  <b-select
                    v-model="finger1Hemorragias"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger1HemorragiasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger2Hemorragias"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger2HemorragiasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger3Hemorragias"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger3HemorragiasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger4Hemorragias"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger4HemorragiasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger5Hemorragias"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger5HemorragiasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger6Hemorragias"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger6HemorragiasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger7Hemorragias"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger7HemorragiasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger8Hemorragias"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger8HemorragiasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger9Hemorragias"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger9HemorragiasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger10Hemorragias"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger10HemorragiasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
            </tr>

            <tr>
              <td>Hiperqueratosis Subungular</td>
              <td></td>

              <td>
                <b-field>
                  <b-select
                    v-model="finger1Hiperqueratosis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger1HiperqueratosisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger2Hiperqueratosis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger2HiperqueratosisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger3Hiperqueratosis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger3HiperqueratosisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger4Hiperqueratosis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger4HiperqueratosisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger5Hiperqueratosis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger5HiperqueratosisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger6Hiperqueratosis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger6HiperqueratosisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger7Hiperqueratosis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger7HiperqueratosisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger8Hiperqueratosis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger8HiperqueratosisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger9Hiperqueratosis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger9HiperqueratosisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger10Hiperqueratosis"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger10HiperqueratosisAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
            </tr>

            <tr>
              <td>Manchas rojas en la lúnula</td>
              <td></td>

              <td>
                <b-field>
                  <b-select
                    v-model="finger1Manchas"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger1ManchasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger2Manchas"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger2ManchasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger3Manchas"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger3ManchasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger4Manchas"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger4ManchasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger5Manchas"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger5ManchasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger6Manchas"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger6ManchasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger7Manchas"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger7ManchasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger8Manchas"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger8ManchasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger9Manchas"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger9ManchasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
              <td>
                <b-field>
                  <b-select
                    v-model="finger10Manchas"
                    @input="saveTermporalSulrvey"
                    :disabled="queryMode"
                    rounded
                  >
                    <option
                      v-for="(item, index) in finger10ManchasAnswers"
                      :key="index"
                      :value="index"
                      >{{ index }}</option
                    >
                  </b-select>
                </b-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="block has-text-centered title">
        NAPSI MM/Resultado: {{ score }}
        <span v-if="isIncompleted">(Incompleto)</span>
      </div>

      <div class="buttons is-centered" v-if="!queryMode">
        <b-button class="clear-button" icon-right="block-helper" @click="clear"
          >Limpiar</b-button
        >
        <b-button class="save-button" icon-right="check-bold" @click="validate"
          >Guardar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { NAPSIMM as questions } from "../../../data/questions";
import { NAPSIMM as answers } from "../../../data/answers";
export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      finger1Onicolisis:
        answers.ONICOLISIS.NAPSIMM_DEDO1_ONICOLISIS.NAPSIMM_DEDO1_ONICOLISIS_R1,
      finger2Onicolisis:
        answers.ONICOLISIS.NAPSIMM_DEDO2_ONICOLISIS.NAPSIMM_DEDO2_ONICOLISIS_R1,
      finger3Onicolisis:
        answers.ONICOLISIS.NAPSIMM_DEDO3_ONICOLISIS.NAPSIMM_DEDO3_ONICOLISIS_R1,
      finger4Onicolisis:
        answers.ONICOLISIS.NAPSIMM_DEDO4_ONICOLISIS.NAPSIMM_DEDO4_ONICOLISIS_R1,
      finger5Onicolisis:
        answers.ONICOLISIS.NAPSIMM_DEDO5_ONICOLISIS.NAPSIMM_DEDO5_ONICOLISIS_R1,
      finger6Onicolisis:
        answers.ONICOLISIS.NAPSIMM_DEDO6_ONICOLISIS.NAPSIMM_DEDO6_ONICOLISIS_R1,
      finger7Onicolisis:
        answers.ONICOLISIS.NAPSIMM_DEDO7_ONICOLISIS.NAPSIMM_DEDO7_ONICOLISIS_R1,
      finger8Onicolisis:
        answers.ONICOLISIS.NAPSIMM_DEDO8_ONICOLISIS.NAPSIMM_DEDO8_ONICOLISIS_R1,
      finger9Onicolisis:
        answers.ONICOLISIS.NAPSIMM_DEDO9_ONICOLISIS.NAPSIMM_DEDO9_ONICOLISIS_R1,
      finger10Onicolisis:
        answers.ONICOLISIS.NAPSIMM_DEDO10_ONICOLISIS
          .NAPSIMM_DEDO10_ONICOLISIS_R1,
      finger1Pitts: answers.PITTS.NAPSIMM_DEDO1_PITTS.NAPSIMM_DEDO1_PITTS_R1,
      finger2Pitts: answers.PITTS.NAPSIMM_DEDO2_PITTS.NAPSIMM_DEDO2_PITTS_R1,
      finger3Pitts: answers.PITTS.NAPSIMM_DEDO3_PITTS.NAPSIMM_DEDO3_PITTS_R1,
      finger4Pitts: answers.PITTS.NAPSIMM_DEDO4_PITTS.NAPSIMM_DEDO4_PITTS_R1,
      finger5Pitts: answers.PITTS.NAPSIMM_DEDO5_PITTS.NAPSIMM_DEDO5_PITTS_R1,
      finger6Pitts: answers.PITTS.NAPSIMM_DEDO6_PITTS.NAPSIMM_DEDO6_PITTS_R1,
      finger7Pitts: answers.PITTS.NAPSIMM_DEDO7_PITTS.NAPSIMM_DEDO7_PITTS_R1,
      finger8Pitts: answers.PITTS.NAPSIMM_DEDO8_PITTS.NAPSIMM_DEDO8_PITTS_R1,
      finger9Pitts: answers.PITTS.NAPSIMM_DEDO9_PITTS.NAPSIMM_DEDO9_PITTS_R1,
      finger10Pitts: answers.PITTS.NAPSIMM_DEDO10_PITTS.NAPSIMM_DEDO10_PITTS_R1,
      finger1Onicodistrofia:
        answers.ONICODISTROFIA.NAPSIMM_DEDO1_ONICODISTROFIA
          .NAPSIMM_DEDO1_ONICODISTROFIA_R1,
      finger2Onicodistrofia:
        answers.ONICODISTROFIA.NAPSIMM_DEDO2_ONICODISTROFIA
          .NAPSIMM_DEDO2_ONICODISTROFIA_R1,
      finger3Onicodistrofia:
        answers.ONICODISTROFIA.NAPSIMM_DEDO3_ONICODISTROFIA
          .NAPSIMM_DEDO3_ONICODISTROFIA_R1,
      finger4Onicodistrofia:
        answers.ONICODISTROFIA.NAPSIMM_DEDO4_ONICODISTROFIA
          .NAPSIMM_DEDO4_ONICODISTROFIA_R1,
      finger5Onicodistrofia:
        answers.ONICODISTROFIA.NAPSIMM_DEDO5_ONICODISTROFIA
          .NAPSIMM_DEDO5_ONICODISTROFIA_R1,
      finger6Onicodistrofia:
        answers.ONICODISTROFIA.NAPSIMM_DEDO6_ONICODISTROFIA
          .NAPSIMM_DEDO6_ONICODISTROFIA_R1,
      finger7Onicodistrofia:
        answers.ONICODISTROFIA.NAPSIMM_DEDO7_ONICODISTROFIA
          .NAPSIMM_DEDO7_ONICODISTROFIA_R1,
      finger8Onicodistrofia:
        answers.ONICODISTROFIA.NAPSIMM_DEDO8_ONICODISTROFIA
          .NAPSIMM_DEDO8_ONICODISTROFIA_R1,
      finger9Onicodistrofia:
        answers.ONICODISTROFIA.NAPSIMM_DEDO9_ONICODISTROFIA
          .NAPSIMM_DEDO9_ONICODISTROFIA_R1,
      finger10Onicodistrofia:
        answers.ONICODISTROFIA.NAPSIMM_DEDO10_ONICODISTROFIA
          .NAPSIMM_DEDO10_ONICODISTROFIA_R1,
      finger1Leuconiquia:
        answers.LEUCONIQUIA.NAPSIMM_DEDO1_LEUCONIQUIA
          .NAPSIMM_DEDO1_LEUCONIQUIA_R1,
      finger2Leuconiquia:
        answers.LEUCONIQUIA.NAPSIMM_DEDO2_LEUCONIQUIA
          .NAPSIMM_DEDO2_LEUCONIQUIA_R1,
      finger3Leuconiquia:
        answers.LEUCONIQUIA.NAPSIMM_DEDO3_LEUCONIQUIA
          .NAPSIMM_DEDO3_LEUCONIQUIA_R1,
      finger4Leuconiquia:
        answers.LEUCONIQUIA.NAPSIMM_DEDO4_LEUCONIQUIA
          .NAPSIMM_DEDO4_LEUCONIQUIA_R1,
      finger5Leuconiquia:
        answers.LEUCONIQUIA.NAPSIMM_DEDO5_LEUCONIQUIA
          .NAPSIMM_DEDO5_LEUCONIQUIA_R1,
      finger6Leuconiquia:
        answers.LEUCONIQUIA.NAPSIMM_DEDO6_LEUCONIQUIA
          .NAPSIMM_DEDO6_LEUCONIQUIA_R1,
      finger7Leuconiquia:
        answers.LEUCONIQUIA.NAPSIMM_DEDO7_LEUCONIQUIA
          .NAPSIMM_DEDO7_LEUCONIQUIA_R1,
      finger8Leuconiquia:
        answers.LEUCONIQUIA.NAPSIMM_DEDO8_LEUCONIQUIA
          .NAPSIMM_DEDO8_LEUCONIQUIA_R1,
      finger9Leuconiquia:
        answers.LEUCONIQUIA.NAPSIMM_DEDO9_LEUCONIQUIA
          .NAPSIMM_DEDO9_LEUCONIQUIA_R1,
      finger10Leuconiquia:
        answers.LEUCONIQUIA.NAPSIMM_DEDO10_LEUCONIQUIA
          .NAPSIMM_DEDO10_LEUCONIQUIA_R1,
      finger1Hemorragias:
        answers.HEMORRAGIAS.NAPSIMM_DEDO1_HEMORRAGIAS
          .NAPSIMM_DEDO1_HEMORRAGIAS_R1,
      finger2Hemorragias:
        answers.HEMORRAGIAS.NAPSIMM_DEDO2_HEMORRAGIAS
          .NAPSIMM_DEDO2_HEMORRAGIAS_R1,
      finger3Hemorragias:
        answers.HEMORRAGIAS.NAPSIMM_DEDO3_HEMORRAGIAS
          .NAPSIMM_DEDO3_HEMORRAGIAS_R1,
      finger4Hemorragias:
        answers.HEMORRAGIAS.NAPSIMM_DEDO4_HEMORRAGIAS
          .NAPSIMM_DEDO4_HEMORRAGIAS_R1,
      finger5Hemorragias:
        answers.HEMORRAGIAS.NAPSIMM_DEDO5_HEMORRAGIAS
          .NAPSIMM_DEDO5_HEMORRAGIAS_R1,
      finger6Hemorragias:
        answers.HEMORRAGIAS.NAPSIMM_DEDO6_HEMORRAGIAS
          .NAPSIMM_DEDO6_HEMORRAGIAS_R1,
      finger7Hemorragias:
        answers.HEMORRAGIAS.NAPSIMM_DEDO7_HEMORRAGIAS
          .NAPSIMM_DEDO7_HEMORRAGIAS_R1,
      finger8Hemorragias:
        answers.HEMORRAGIAS.NAPSIMM_DEDO8_HEMORRAGIAS
          .NAPSIMM_DEDO8_HEMORRAGIAS_R1,
      finger9Hemorragias:
        answers.HEMORRAGIAS.NAPSIMM_DEDO9_HEMORRAGIAS
          .NAPSIMM_DEDO9_HEMORRAGIAS_R1,
      finger10Hemorragias:
        answers.HEMORRAGIAS.NAPSIMM_DEDO10_HEMORRAGIAS
          .NAPSIMM_DEDO10_HEMORRAGIAS_R1,
      finger1Hiperqueratosis:
        answers.HIPERQUERATOSIS.NAPSIMM_DEDO1_HIPERQUERATOSIS
          .NAPSIMM_DEDO1_HIPERQUERATOSIS_R1,
      finger2Hiperqueratosis:
        answers.HIPERQUERATOSIS.NAPSIMM_DEDO2_HIPERQUERATOSIS
          .NAPSIMM_DEDO2_HIPERQUERATOSIS_R1,
      finger3Hiperqueratosis:
        answers.HIPERQUERATOSIS.NAPSIMM_DEDO3_HIPERQUERATOSIS
          .NAPSIMM_DEDO3_HIPERQUERATOSIS_R1,
      finger4Hiperqueratosis:
        answers.HIPERQUERATOSIS.NAPSIMM_DEDO4_HIPERQUERATOSIS
          .NAPSIMM_DEDO4_HIPERQUERATOSIS_R1,
      finger5Hiperqueratosis:
        answers.HIPERQUERATOSIS.NAPSIMM_DEDO5_HIPERQUERATOSIS
          .NAPSIMM_DEDO5_HIPERQUERATOSIS_R1,
      finger6Hiperqueratosis:
        answers.HIPERQUERATOSIS.NAPSIMM_DEDO6_HIPERQUERATOSIS
          .NAPSIMM_DEDO6_HIPERQUERATOSIS_R1,
      finger7Hiperqueratosis:
        answers.HIPERQUERATOSIS.NAPSIMM_DEDO7_HIPERQUERATOSIS
          .NAPSIMM_DEDO7_HIPERQUERATOSIS_R1,
      finger8Hiperqueratosis:
        answers.HIPERQUERATOSIS.NAPSIMM_DEDO8_HIPERQUERATOSIS
          .NAPSIMM_DEDO8_HIPERQUERATOSIS_R1,
      finger9Hiperqueratosis:
        answers.HIPERQUERATOSIS.NAPSIMM_DEDO9_HIPERQUERATOSIS
          .NAPSIMM_DEDO9_HIPERQUERATOSIS_R1,
      finger10Hiperqueratosis:
        answers.HIPERQUERATOSIS.NAPSIMM_DEDO10_HIPERQUERATOSIS
          .NAPSIMM_DEDO10_HIPERQUERATOSIS_R1,
      finger1Manchas:
        answers.MANCHAS.NAPSIMM_DEDO1_MANCHAS.NAPSIMM_DEDO1_MANCHAS_R1,
      finger2Manchas:
        answers.MANCHAS.NAPSIMM_DEDO2_MANCHAS.NAPSIMM_DEDO2_MANCHAS_R1,
      finger3Manchas:
        answers.MANCHAS.NAPSIMM_DEDO3_MANCHAS.NAPSIMM_DEDO3_MANCHAS_R1,
      finger4Manchas:
        answers.MANCHAS.NAPSIMM_DEDO4_MANCHAS.NAPSIMM_DEDO4_MANCHAS_R1,
      finger5Manchas:
        answers.MANCHAS.NAPSIMM_DEDO5_MANCHAS.NAPSIMM_DEDO5_MANCHAS_R1,
      finger6Manchas:
        answers.MANCHAS.NAPSIMM_DEDO6_MANCHAS.NAPSIMM_DEDO6_MANCHAS_R1,
      finger7Manchas:
        answers.MANCHAS.NAPSIMM_DEDO7_MANCHAS.NAPSIMM_DEDO7_MANCHAS_R1,
      finger8Manchas:
        answers.MANCHAS.NAPSIMM_DEDO8_MANCHAS.NAPSIMM_DEDO8_MANCHAS_R1,
      finger9Manchas:
        answers.MANCHAS.NAPSIMM_DEDO9_MANCHAS.NAPSIMM_DEDO9_MANCHAS_R1,
      finger10Manchas:
        answers.MANCHAS.NAPSIMM_DEDO10_MANCHAS.NAPSIMM_DEDO10_MANCHAS_R1,
      finger1OnicolisisAnswers: [],
      finger2OnicolisisAnswers: [],
      finger3OnicolisisAnswers: [],
      finger4OnicolisisAnswers: [],
      finger5OnicolisisAnswers: [],
      finger6OnicolisisAnswers: [],
      finger7OnicolisisAnswers: [],
      finger8OnicolisisAnswers: [],
      finger9OnicolisisAnswers: [],
      finger10OnicolisisAnswers: [],
      finger1PittsAnswers: [],
      finger2PittsAnswers: [],
      finger3PittsAnswers: [],
      finger4PittsAnswers: [],
      finger5PittsAnswers: [],
      finger6PittsAnswers: [],
      finger7PittsAnswers: [],
      finger8PittsAnswers: [],
      finger9PittsAnswers: [],
      finger10PittsAnswers: [],
      finger1OnicodistrofiaAnswers: [],
      finger2OnicodistrofiaAnswers: [],
      finger3OnicodistrofiaAnswers: [],
      finger4OnicodistrofiaAnswers: [],
      finger5OnicodistrofiaAnswers: [],
      finger6OnicodistrofiaAnswers: [],
      finger7OnicodistrofiaAnswers: [],
      finger8OnicodistrofiaAnswers: [],
      finger9OnicodistrofiaAnswers: [],
      finger10OnicodistrofiaAnswers: [],
      finger1LeuconiquiaAnswers: [],
      finger2LeuconiquiaAnswers: [],
      finger3LeuconiquiaAnswers: [],
      finger4LeuconiquiaAnswers: [],
      finger5LeuconiquiaAnswers: [],
      finger6LeuconiquiaAnswers: [],
      finger7LeuconiquiaAnswers: [],
      finger8LeuconiquiaAnswers: [],
      finger9LeuconiquiaAnswers: [],
      finger10LeuconiquiaAnswers: [],
      finger1HemorragiasAnswers: [],
      finger2HemorragiasAnswers: [],
      finger3HemorragiasAnswers: [],
      finger4HemorragiasAnswers: [],
      finger5HemorragiasAnswers: [],
      finger6HemorragiasAnswers: [],
      finger7HemorragiasAnswers: [],
      finger8HemorragiasAnswers: [],
      finger9HemorragiasAnswers: [],
      finger10HemorragiasAnswers: [],
      finger1HiperqueratosisAnswers: [],
      finger2HiperqueratosisAnswers: [],
      finger3HiperqueratosisAnswers: [],
      finger4HiperqueratosisAnswers: [],
      finger5HiperqueratosisAnswers: [],
      finger6HiperqueratosisAnswers: [],
      finger7HiperqueratosisAnswers: [],
      finger8HiperqueratosisAnswers: [],
      finger9HiperqueratosisAnswers: [],
      finger10HiperqueratosisAnswers: [],
      finger1ManchasAnswers: [],
      finger2ManchasAnswers: [],
      finger3ManchasAnswers: [],
      finger4ManchasAnswers: [],
      finger5ManchasAnswers: [],
      finger6ManchasAnswers: [],
      finger7ManchasAnswers: [],
      finger8ManchasAnswers: [],
      finger9ManchasAnswers: [],
      finger10ManchasAnswers: [],
      questionOnicolisisKeys: [],
      questionPittsKeys: [],
      questionOnicodistrofiaKeys: [],
      questionLeuconiquiaKeys: [],
      questionHemorragiasKeys: [],
      questionHiperqueratosisKeys: [],
      questionManchasKeys: [],
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "NapsimmList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    this.setKeys();

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.finger1Onicolisis = this.$route.params.temporalData.finger1Onicolisis;
      this.finger2Onicolisis = this.$route.params.temporalData.finger2Onicolisis;
      this.finger3Onicolisis = this.$route.params.temporalData.finger3Onicolisis;
      this.finger4Onicolisis = this.$route.params.temporalData.finger4Onicolisis;
      this.finger5Onicolisis = this.$route.params.temporalData.finger5Onicolisis;
      this.finger6Onicolisis = this.$route.params.temporalData.finger6Onicolisis;
      this.finger7Onicolisis = this.$route.params.temporalData.finger7Onicolisis;
      this.finger8Onicolisis = this.$route.params.temporalData.finger8Onicolisis;
      this.finger9Onicolisis = this.$route.params.temporalData.finger9Onicolisis;
      this.finger10Onicolisis = this.$route.params.temporalData.finger10Onicolisis;
      this.finger1Pitts = this.$route.params.temporalData.finger1Pitts;
      this.finger2Pitts = this.$route.params.temporalData.finger2Pitts;
      this.finger3Pitts = this.$route.params.temporalData.finger3Pitts;
      this.finger4Pitts = this.$route.params.temporalData.finger4Pitts;
      this.finger5Pitts = this.$route.params.temporalData.finger5Pitts;
      this.finger6Pitts = this.$route.params.temporalData.finger6Pitts;
      this.finger7Pitts = this.$route.params.temporalData.finger7Pitts;
      this.finger8Pitts = this.$route.params.temporalData.finger8Pitts;
      this.finger9Pitts = this.$route.params.temporalData.finger9Pitts;
      this.finger10Pitts = this.$route.params.temporalData.finger10Pitts;
      this.finger1Onicodistrofia = this.$route.params.temporalData.finger1Onicodistrofia;
      this.finger2Onicodistrofia = this.$route.params.temporalData.finger2Onicodistrofia;
      this.finger3Onicodistrofia = this.$route.params.temporalData.finger3Onicodistrofia;
      this.finger4Onicodistrofia = this.$route.params.temporalData.finger4Onicodistrofia;
      this.finger5Onicodistrofia = this.$route.params.temporalData.finger5Onicodistrofia;
      this.finger6Onicodistrofia = this.$route.params.temporalData.finger6Onicodistrofia;
      this.finger7Onicodistrofia = this.$route.params.temporalData.finger7Onicodistrofia;
      this.finger8Onicodistrofia = this.$route.params.temporalData.finger8Onicodistrofia;
      this.finger9Onicodistrofia = this.$route.params.temporalData.finger9Onicodistrofia;
      this.finger10Onicodistrofia = this.$route.params.temporalData.finger10Onicodistrofia;
      this.finger1Leuconiquia = this.$route.params.temporalData.finger1Leuconiquia;
      this.finger2Leuconiquia = this.$route.params.temporalData.finger2Leuconiquia;
      this.finger3Leuconiquia = this.$route.params.temporalData.finger3Leuconiquia;
      this.finger4Leuconiquia = this.$route.params.temporalData.finger4Leuconiquia;
      this.finger5Leuconiquia = this.$route.params.temporalData.finger5Leuconiquia;
      this.finger6Leuconiquia = this.$route.params.temporalData.finger6Leuconiquia;
      this.finger7Leuconiquia = this.$route.params.temporalData.finger7Leuconiquia;
      this.finger8Leuconiquia = this.$route.params.temporalData.finger8Leuconiquia;
      this.finger9Leuconiquia = this.$route.params.temporalData.finger9Leuconiquia;
      this.finger10Leuconiquia = this.$route.params.temporalData.finger10Leuconiquia;
      this.finger1Hemorragias = this.$route.params.temporalData.finger1Hemorragias;
      this.finger2Hemorragias = this.$route.params.temporalData.finger2Hemorragias;
      this.finger3Hemorragias = this.$route.params.temporalData.finger3Hemorragias;
      this.finger4Hemorragias = this.$route.params.temporalData.finger4Hemorragias;
      this.finger5Hemorragias = this.$route.params.temporalData.finger5Hemorragias;
      this.finger6Hemorragias = this.$route.params.temporalData.finger6Hemorragias;
      this.finger7Hemorragias = this.$route.params.temporalData.finger7Hemorragias;
      this.finger8Hemorragias = this.$route.params.temporalData.finger8Hemorragias;
      this.finger9Hemorragias = this.$route.params.temporalData.finger9Hemorragias;
      this.finger10Hemorragias = this.$route.params.temporalData.finger10Hemorragias;
      this.finger1Hiperqueratosis = this.$route.params.temporalData.finger1Hiperqueratosis;
      this.finger2Hiperqueratosis = this.$route.params.temporalData.finger2Hiperqueratosis;
      this.finger3Hiperqueratosis = this.$route.params.temporalData.finger3Hiperqueratosis;
      this.finger4Hiperqueratosis = this.$route.params.temporalData.finger4Hiperqueratosis;
      this.finger5Hiperqueratosis = this.$route.params.temporalData.finger5Hiperqueratosis;
      this.finger6Hiperqueratosis = this.$route.params.temporalData.finger6Hiperqueratosis;
      this.finger7Hiperqueratosis = this.$route.params.temporalData.finger7Hiperqueratosis;
      this.finger8Hiperqueratosis = this.$route.params.temporalData.finger8Hiperqueratosis;
      this.finger9Hiperqueratosis = this.$route.params.temporalData.finger9Hiperqueratosis;
      this.finger10Hiperqueratosis = this.$route.params.temporalData.finger10Hiperqueratosis;
      this.finger1Manchas = this.$route.params.temporalData.finger1Manchas;
      this.finger2Manchas = this.$route.params.temporalData.finger2Manchas;
      this.finger3Manchas = this.$route.params.temporalData.finger3Manchas;
      this.finger4Manchas = this.$route.params.temporalData.finger4Manchas;
      this.finger5Manchas = this.$route.params.temporalData.finger5Manchas;
      this.finger6Manchas = this.$route.params.temporalData.finger6Manchas;
      this.finger7Manchas = this.$route.params.temporalData.finger7Manchas;
      this.finger8Manchas = this.$route.params.temporalData.finger8Manchas;
      this.finger9Manchas = this.$route.params.temporalData.finger9Manchas;
      this.finger10Manchas = this.$route.params.temporalData.finger10Manchas;
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return (
        parseInt(this.finger1Onicolisis || 0) +
        parseInt(this.finger2Onicolisis || 0) +
        parseInt(this.finger3Onicolisis || 0) +
        parseInt(this.finger4Onicolisis || 0) +
        parseInt(this.finger5Onicolisis || 0) +
        parseInt(this.finger6Onicolisis || 0) +
        parseInt(this.finger7Onicolisis || 0) +
        parseInt(this.finger8Onicolisis || 0) +
        parseInt(this.finger9Onicolisis || 0) +
        parseInt(this.finger10Onicolisis || 0) +
        parseInt(this.finger1Pitts || 0) +
        parseInt(this.finger2Pitts || 0) +
        parseInt(this.finger3Pitts || 0) +
        parseInt(this.finger4Pitts || 0) +
        parseInt(this.finger5Pitts || 0) +
        parseInt(this.finger6Pitts || 0) +
        parseInt(this.finger7Pitts || 0) +
        parseInt(this.finger8Pitts || 0) +
        parseInt(this.finger9Pitts || 0) +
        parseInt(this.finger10Pitts || 0) +
        parseInt(this.finger1Onicodistrofia || 0) +
        parseInt(this.finger2Onicodistrofia || 0) +
        parseInt(this.finger3Onicodistrofia || 0) +
        parseInt(this.finger4Onicodistrofia || 0) +
        parseInt(this.finger5Onicodistrofia || 0) +
        parseInt(this.finger6Onicodistrofia || 0) +
        parseInt(this.finger7Onicodistrofia || 0) +
        parseInt(this.finger8Onicodistrofia || 0) +
        parseInt(this.finger9Onicodistrofia || 0) +
        parseInt(this.finger10Onicodistrofia || 0) +
        parseInt(this.finger1Leuconiquia || 0) +
        parseInt(this.finger2Leuconiquia || 0) +
        parseInt(this.finger3Leuconiquia || 0) +
        parseInt(this.finger4Leuconiquia || 0) +
        parseInt(this.finger5Leuconiquia || 0) +
        parseInt(this.finger6Leuconiquia || 0) +
        parseInt(this.finger7Leuconiquia || 0) +
        parseInt(this.finger8Leuconiquia || 0) +
        parseInt(this.finger9Leuconiquia || 0) +
        parseInt(this.finger10Leuconiquia || 0) +
        parseInt(this.finger1Hemorragias || 0) +
        parseInt(this.finger2Hemorragias || 0) +
        parseInt(this.finger3Hemorragias || 0) +
        parseInt(this.finger4Hemorragias || 0) +
        parseInt(this.finger5Hemorragias || 0) +
        parseInt(this.finger6Hemorragias || 0) +
        parseInt(this.finger7Hemorragias || 0) +
        parseInt(this.finger8Hemorragias || 0) +
        parseInt(this.finger9Hemorragias || 0) +
        parseInt(this.finger10Hemorragias || 0) +
        parseInt(this.finger1Hiperqueratosis || 0) +
        parseInt(this.finger2Hiperqueratosis || 0) +
        parseInt(this.finger3Hiperqueratosis || 0) +
        parseInt(this.finger4Hiperqueratosis || 0) +
        parseInt(this.finger5Hiperqueratosis || 0) +
        parseInt(this.finger6Hiperqueratosis || 0) +
        parseInt(this.finger7Hiperqueratosis || 0) +
        parseInt(this.finger8Hiperqueratosis || 0) +
        parseInt(this.finger9Hiperqueratosis || 0) +
        parseInt(this.finger10Hiperqueratosis || 0) +
        parseInt(this.finger1Manchas || 0) +
        parseInt(this.finger2Manchas || 0) +
        parseInt(this.finger3Manchas || 0) +
        parseInt(this.finger4Manchas || 0) +
        parseInt(this.finger5Manchas || 0) +
        parseInt(this.finger6Manchas || 0) +
        parseInt(this.finger7Manchas || 0) +
        parseInt(this.finger8Manchas || 0) +
        parseInt(this.finger9Manchas || 0) +
        parseInt(this.finger10Manchas || 0)
      );
    },
    isIncompleted() {
     return this.score === 0;
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    setKeys() {
      this.questionOnicolisisKeys = Object.keys(this.questions.ONICOLISIS);
      this.questionPittsKeys = Object.keys(this.questions.PITTS);
      this.questionOnicodistrofiaKeys = Object.keys(
        this.questions.ONICODISTROFIA
      );
      this.questionLeuconiquiaKeys = Object.keys(this.questions.LEUCONIQUIA);
      this.questionHemorragiasKeys = Object.keys(this.questions.HEMORRAGIAS);
      this.questionHiperqueratosisKeys = Object.keys(
        this.questions.HIPERQUERATOSIS
      );
      this.questionManchasKeys = Object.keys(this.questions.MANCHAS);

      this.finger1OnicolisisAnswers = Object.entries(
        this.answers.ONICOLISIS[this.questionOnicolisisKeys[0]]
      );

      this.finger2OnicolisisAnswers = Object.entries(
        this.answers.ONICOLISIS[this.questionOnicolisisKeys[1]]
      );

      this.finger3OnicolisisAnswers = Object.entries(
        this.answers.ONICOLISIS[this.questionOnicolisisKeys[2]]
      );

      this.finger4OnicolisisAnswers = Object.entries(
        this.answers.ONICOLISIS[this.questionOnicolisisKeys[3]]
      );

      this.finger5OnicolisisAnswers = Object.entries(
        this.answers.ONICOLISIS[this.questionOnicolisisKeys[4]]
      );

      this.finger6OnicolisisAnswers = Object.entries(
        this.answers.ONICOLISIS[this.questionOnicolisisKeys[5]]
      );

      this.finger7OnicolisisAnswers = Object.entries(
        this.answers.ONICOLISIS[this.questionOnicolisisKeys[6]]
      );

      this.finger8OnicolisisAnswers = Object.entries(
        this.answers.ONICOLISIS[this.questionOnicolisisKeys[7]]
      );

      this.finger9OnicolisisAnswers = Object.entries(
        this.answers.ONICOLISIS[this.questionOnicolisisKeys[8]]
      );

      this.finger10OnicolisisAnswers = Object.entries(
        this.answers.ONICOLISIS[this.questionOnicolisisKeys[9]]
      );

      /*********** */

      this.finger1PittsAnswers = Object.entries(
        this.answers.PITTS[this.questionPittsKeys[0]]
      );

      this.finger2PittsAnswers = Object.entries(
        this.answers.PITTS[this.questionPittsKeys[1]]
      );

      this.finger3PittsAnswers = Object.entries(
        this.answers.PITTS[this.questionPittsKeys[2]]
      );

      this.finger4PittsAnswers = Object.entries(
        this.answers.PITTS[this.questionPittsKeys[3]]
      );

      this.finger5PittsAnswers = Object.entries(
        this.answers.PITTS[this.questionPittsKeys[4]]
      );

      this.finger6PittsAnswers = Object.entries(
        this.answers.PITTS[this.questionPittsKeys[5]]
      );

      this.finger7PittsAnswers = Object.entries(
        this.answers.PITTS[this.questionPittsKeys[6]]
      );

      this.finger8PittsAnswers = Object.entries(
        this.answers.PITTS[this.questionPittsKeys[7]]
      );

      this.finger9PittsAnswers = Object.entries(
        this.answers.PITTS[this.questionPittsKeys[8]]
      );

      this.finger10PittsAnswers = Object.entries(
        this.answers.PITTS[this.questionPittsKeys[9]]
      );

      /************* */
      this.finger1OnicodistrofiaAnswers = Object.entries(
        this.answers.ONICODISTROFIA[this.questionOnicodistrofiaKeys[0]]
      );

      this.finger2OnicodistrofiaAnswers = Object.entries(
        this.answers.ONICODISTROFIA[this.questionOnicodistrofiaKeys[1]]
      );

      this.finger3OnicodistrofiaAnswers = Object.entries(
        this.answers.ONICODISTROFIA[this.questionOnicodistrofiaKeys[2]]
      );

      this.finger4OnicodistrofiaAnswers = Object.entries(
        this.answers.ONICODISTROFIA[this.questionOnicodistrofiaKeys[3]]
      );

      this.finger5OnicodistrofiaAnswers = Object.entries(
        this.answers.ONICODISTROFIA[this.questionOnicodistrofiaKeys[4]]
      );

      this.finger6OnicodistrofiaAnswers = Object.entries(
        this.answers.ONICODISTROFIA[this.questionOnicodistrofiaKeys[5]]
      );

      this.finger7OnicodistrofiaAnswers = Object.entries(
        this.answers.ONICODISTROFIA[this.questionOnicodistrofiaKeys[6]]
      );

      this.finger8OnicodistrofiaAnswers = Object.entries(
        this.answers.ONICODISTROFIA[this.questionOnicodistrofiaKeys[7]]
      );

      this.finger9OnicodistrofiaAnswers = Object.entries(
        this.answers.ONICODISTROFIA[this.questionOnicodistrofiaKeys[8]]
      );

      this.finger10OnicodistrofiaAnswers = Object.entries(
        this.answers.ONICODISTROFIA[this.questionOnicodistrofiaKeys[9]]
      );

      /******************* */

      this.finger1LeuconiquiaAnswers = Object.entries(
        this.answers.LEUCONIQUIA[this.questionLeuconiquiaKeys[0]]
      );

      this.finger2LeuconiquiaAnswers = Object.entries(
        this.answers.LEUCONIQUIA[this.questionLeuconiquiaKeys[1]]
      );

      this.finger3LeuconiquiaAnswers = Object.entries(
        this.answers.LEUCONIQUIA[this.questionLeuconiquiaKeys[2]]
      );

      this.finger4LeuconiquiaAnswers = Object.entries(
        this.answers.LEUCONIQUIA[this.questionLeuconiquiaKeys[3]]
      );

      this.finger5LeuconiquiaAnswers = Object.entries(
        this.answers.LEUCONIQUIA[this.questionLeuconiquiaKeys[4]]
      );

      this.finger6LeuconiquiaAnswers = Object.entries(
        this.answers.LEUCONIQUIA[this.questionLeuconiquiaKeys[5]]
      );

      this.finger7LeuconiquiaAnswers = Object.entries(
        this.answers.LEUCONIQUIA[this.questionLeuconiquiaKeys[6]]
      );

      this.finger8LeuconiquiaAnswers = Object.entries(
        this.answers.LEUCONIQUIA[this.questionLeuconiquiaKeys[7]]
      );

      this.finger9LeuconiquiaAnswers = Object.entries(
        this.answers.LEUCONIQUIA[this.questionLeuconiquiaKeys[8]]
      );

      this.finger10LeuconiquiaAnswers = Object.entries(
        this.answers.LEUCONIQUIA[this.questionLeuconiquiaKeys[9]]
      );

      /**************** */

      this.finger1HemorragiasAnswers = Object.entries(
        this.answers.HEMORRAGIAS[this.questionHemorragiasKeys[0]]
      );

      this.finger2HemorragiasAnswers = Object.entries(
        this.answers.HEMORRAGIAS[this.questionHemorragiasKeys[1]]
      );

      this.finger3HemorragiasAnswers = Object.entries(
        this.answers.HEMORRAGIAS[this.questionHemorragiasKeys[2]]
      );

      this.finger4HemorragiasAnswers = Object.entries(
        this.answers.HEMORRAGIAS[this.questionHemorragiasKeys[3]]
      );

      this.finger5HemorragiasAnswers = Object.entries(
        this.answers.HEMORRAGIAS[this.questionHemorragiasKeys[4]]
      );

      this.finger6HemorragiasAnswers = Object.entries(
        this.answers.HEMORRAGIAS[this.questionHemorragiasKeys[5]]
      );

      this.finger7HemorragiasAnswers = Object.entries(
        this.answers.HEMORRAGIAS[this.questionHemorragiasKeys[6]]
      );

      this.finger8HemorragiasAnswers = Object.entries(
        this.answers.HEMORRAGIAS[this.questionHemorragiasKeys[7]]
      );

      this.finger9HemorragiasAnswers = Object.entries(
        this.answers.HEMORRAGIAS[this.questionHemorragiasKeys[8]]
      );

      this.finger10HemorragiasAnswers = Object.entries(
        this.answers.HEMORRAGIAS[this.questionHemorragiasKeys[9]]
      );

      /************ */

      this.finger1HiperqueratosisAnswers = Object.entries(
        this.answers.HIPERQUERATOSIS[this.questionHiperqueratosisKeys[0]]
      );

      this.finger2HiperqueratosisAnswers = Object.entries(
        this.answers.HIPERQUERATOSIS[this.questionHiperqueratosisKeys[1]]
      );

      this.finger3HiperqueratosisAnswers = Object.entries(
        this.answers.HIPERQUERATOSIS[this.questionHiperqueratosisKeys[2]]
      );

      this.finger4HiperqueratosisAnswers = Object.entries(
        this.answers.HIPERQUERATOSIS[this.questionHiperqueratosisKeys[3]]
      );

      this.finger5HiperqueratosisAnswers = Object.entries(
        this.answers.HIPERQUERATOSIS[this.questionHiperqueratosisKeys[4]]
      );

      this.finger6HiperqueratosisAnswers = Object.entries(
        this.answers.HIPERQUERATOSIS[this.questionHiperqueratosisKeys[5]]
      );

      this.finger7HiperqueratosisAnswers = Object.entries(
        this.answers.HIPERQUERATOSIS[this.questionHiperqueratosisKeys[6]]
      );

      this.finger8HiperqueratosisAnswers = Object.entries(
        this.answers.HIPERQUERATOSIS[this.questionHiperqueratosisKeys[7]]
      );

      this.finger9HiperqueratosisAnswers = Object.entries(
        this.answers.HIPERQUERATOSIS[this.questionHiperqueratosisKeys[8]]
      );

      this.finger10HiperqueratosisAnswers = Object.entries(
        this.answers.HIPERQUERATOSIS[this.questionHiperqueratosisKeys[9]]
      );

      /************ */

      this.finger1ManchasAnswers = Object.entries(
        this.answers.MANCHAS[this.questionManchasKeys[0]]
      );

      this.finger2ManchasAnswers = Object.entries(
        this.answers.MANCHAS[this.questionManchasKeys[1]]
      );

      this.finger3ManchasAnswers = Object.entries(
        this.answers.MANCHAS[this.questionManchasKeys[2]]
      );

      this.finger4ManchasAnswers = Object.entries(
        this.answers.MANCHAS[this.questionManchasKeys[3]]
      );

      this.finger5ManchasAnswers = Object.entries(
        this.answers.MANCHAS[this.questionManchasKeys[4]]
      );

      this.finger6ManchasAnswers = Object.entries(
        this.answers.MANCHAS[this.questionManchasKeys[5]]
      );

      this.finger7ManchasAnswers = Object.entries(
        this.answers.MANCHAS[this.questionManchasKeys[6]]
      );

      this.finger8ManchasAnswers = Object.entries(
        this.answers.MANCHAS[this.questionManchasKeys[7]]
      );

      this.finger9ManchasAnswers = Object.entries(
        this.answers.MANCHAS[this.questionManchasKeys[8]]
      );

      this.finger10ManchasAnswers = Object.entries(
        this.answers.MANCHAS[this.questionManchasKeys[9]]
      );
    },
    clear() {
      this.restoreData.finger1Onicolisis = this.finger1Onicolisis;
      this.restoreData.finger2Onicolisis = this.finger2Onicolisis;
      this.restoreData.finger3Onicolisis = this.finger3Onicolisis;
      this.restoreData.finger4Onicolisis = this.finger4Onicolisis;
      this.restoreData.finger5Onicolisis = this.finger5Onicolisis;
      this.restoreData.finger6Onicolisis = this.finger6Onicolisis;
      this.restoreData.finger7Onicolisis = this.finger7Onicolisis;
      this.restoreData.finger8Onicolisis = this.finger8Onicolisis;
      this.restoreData.finger9Onicolisis = this.finger9Onicolisis;
      this.restoreData.finger10Onicolisis = this.finger10Onicolisis;
      this.restoreData.finger1Pitts = this.finger1Pitts;
      this.restoreData.finger2Pitts = this.finger2Pitts;
      this.restoreData.finger3Pitts = this.finger3Pitts;
      this.restoreData.finger4Pitts = this.finger4Pitts;
      this.restoreData.finger5Pitts = this.finger5Pitts;
      this.restoreData.finger6Pitts = this.finger6Pitts;
      this.restoreData.finger7Pitts = this.finger7Pitts;
      this.restoreData.finger8Pitts = this.finger8Pitts;
      this.restoreData.finger9Pitts = this.finger9Pitts;
      this.restoreData.finger10Pitts = this.finger10Pitts;
      this.restoreData.finger1Onicodistrofia = this.finger1Onicodistrofia;
      this.restoreData.finger2Onicodistrofia = this.finger2Onicodistrofia;
      this.restoreData.finger3Onicodistrofia = this.finger3Onicodistrofia;
      this.restoreData.finger4Onicodistrofia = this.finger4Onicodistrofia;
      this.restoreData.finger5Onicodistrofia = this.finger5Onicodistrofia;
      this.restoreData.finger6Onicodistrofia = this.finger6Onicodistrofia;
      this.restoreData.finger7Onicodistrofia = this.finger7Onicodistrofia;
      this.restoreData.finger8Onicodistrofia = this.finger8Onicodistrofia;
      this.restoreData.finger9Onicodistrofia = this.finger9Onicodistrofia;
      this.restoreData.finger10Onicodistrofia = this.finger10Onicodistrofia;
      this.restoreData.finger1Leuconiquia = this.finger1Leuconiquia;
      this.restoreData.finger2Leuconiquia = this.finger2Leuconiquia;
      this.restoreData.finger3Leuconiquia = this.finger3Leuconiquia;
      this.restoreData.finger4Leuconiquia = this.finger4Leuconiquia;
      this.restoreData.finger5Leuconiquia = this.finger5Leuconiquia;
      this.restoreData.finger6Leuconiquia = this.finger6Leuconiquia;
      this.restoreData.finger7Leuconiquia = this.finger7Leuconiquia;
      this.restoreData.finger8Leuconiquia = this.finger8Leuconiquia;
      this.restoreData.finger9Leuconiquia = this.finger9Leuconiquia;
      this.restoreData.finger10Leuconiquia = this.finger10Leuconiquia;
      this.restoreData.finger1Hemorragias = this.finger1Hemorragias;
      this.restoreData.finger2Hemorragias = this.finger2Hemorragias;
      this.restoreData.finger3Hemorragias = this.finger3Hemorragias;
      this.restoreData.finger4Hemorragias = this.finger4Hemorragias;
      this.restoreData.finger5Hemorragias = this.finger5Hemorragias;
      this.restoreData.finger6Hemorragias = this.finger6Hemorragias;
      this.restoreData.finger7Hemorragias = this.finger7Hemorragias;
      this.restoreData.finger8Hemorragias = this.finger8Hemorragias;
      this.restoreData.finger9Hemorragias = this.finger9Hemorragias;
      this.restoreData.finger10Hemorragias = this.finger10Hemorragias;
      this.restoreData.finger1Hiperqueratosis = this.finger1Hiperqueratosis;
      this.restoreData.finger2Hiperqueratosis = this.finger2Hiperqueratosis;
      this.restoreData.finger3Hiperqueratosis = this.finger3Hiperqueratosis;
      this.restoreData.finger4Hiperqueratosis = this.finger4Hiperqueratosis;
      this.restoreData.finger5Hiperqueratosis = this.finger5Hiperqueratosis;
      this.restoreData.finger6Hiperqueratosis = this.finger6Hiperqueratosis;
      this.restoreData.finger7Hiperqueratosis = this.finger7Hiperqueratosis;
      this.restoreData.finger8Hiperqueratosis = this.finger8Hiperqueratosis;
      this.restoreData.finger9Hiperqueratosis = this.finger9Hiperqueratosis;
      this.restoreData.finger10Hiperqueratosis = this.finger10Hiperqueratosis;
      this.restoreData.finger1Manchas = this.finger1Manchas;
      this.restoreData.finger2Manchas = this.finger2Manchas;
      this.restoreData.finger3Manchas = this.finger3Manchas;
      this.restoreData.finger4Manchas = this.finger4Manchas;
      this.restoreData.finger5Manchas = this.finger5Manchas;
      this.restoreData.finger6Manchas = this.finger6Manchas;
      this.restoreData.finger7Manchas = this.finger7Manchas;
      this.restoreData.finger8Manchas = this.finger8Manchas;
      this.restoreData.finger9Manchas = this.finger9Manchas;
      this.restoreData.finger10Manchas = this.finger10Manchas;

      this.cleanForms();
      this.clearTemporalSurveys();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta NAPSI MM.");
    },
    cleanForms() {
      this.finger1Onicolisis = this.answers.ONICOLISIS.NAPSIMM_DEDO1_ONICOLISIS.NAPSIMM_DEDO1_ONICOLISIS_R1;
      this.finger2Onicolisis = this.answers.ONICOLISIS.NAPSIMM_DEDO2_ONICOLISIS.NAPSIMM_DEDO2_ONICOLISIS_R1;
      this.finger3Onicolisis = this.answers.ONICOLISIS.NAPSIMM_DEDO3_ONICOLISIS.NAPSIMM_DEDO3_ONICOLISIS_R1;
      this.finger4Onicolisis = this.answers.ONICOLISIS.NAPSIMM_DEDO4_ONICOLISIS.NAPSIMM_DEDO4_ONICOLISIS_R1;
      this.finger5Onicolisis = this.answers.ONICOLISIS.NAPSIMM_DEDO5_ONICOLISIS.NAPSIMM_DEDO5_ONICOLISIS_R1;
      this.finger6Onicolisis = this.answers.ONICOLISIS.NAPSIMM_DEDO6_ONICOLISIS.NAPSIMM_DEDO6_ONICOLISIS_R1;
      this.finger7Onicolisis = this.answers.ONICOLISIS.NAPSIMM_DEDO7_ONICOLISIS.NAPSIMM_DEDO7_ONICOLISIS_R1;
      this.finger8Onicolisis = this.answers.ONICOLISIS.NAPSIMM_DEDO8_ONICOLISIS.NAPSIMM_DEDO8_ONICOLISIS_R1;
      this.finger9Onicolisis = this.answers.ONICOLISIS.NAPSIMM_DEDO9_ONICOLISIS.NAPSIMM_DEDO9_ONICOLISIS_R1;
      this.finger10Onicolisis = this.answers.ONICOLISIS.NAPSIMM_DEDO10_ONICOLISIS.NAPSIMM_DEDO10_ONICOLISIS_R1;
      this.finger1Pitts = this.answers.PITTS.NAPSIMM_DEDO1_PITTS.NAPSIMM_DEDO1_PITTS_R1;
      this.finger2Pitts = this.answers.PITTS.NAPSIMM_DEDO2_PITTS.NAPSIMM_DEDO2_PITTS_R1;
      this.finger3Pitts = this.answers.PITTS.NAPSIMM_DEDO3_PITTS.NAPSIMM_DEDO3_PITTS_R1;
      this.finger4Pitts = this.answers.PITTS.NAPSIMM_DEDO4_PITTS.NAPSIMM_DEDO4_PITTS_R1;
      this.finger5Pitts = this.answers.PITTS.NAPSIMM_DEDO5_PITTS.NAPSIMM_DEDO5_PITTS_R1;
      this.finger6Pitts = this.answers.PITTS.NAPSIMM_DEDO6_PITTS.NAPSIMM_DEDO6_PITTS_R1;
      this.finger7Pitts = this.answers.PITTS.NAPSIMM_DEDO7_PITTS.NAPSIMM_DEDO7_PITTS_R1;
      this.finger8Pitts = this.answers.PITTS.NAPSIMM_DEDO8_PITTS.NAPSIMM_DEDO8_PITTS_R1;
      this.finger9Pitts = this.answers.PITTS.NAPSIMM_DEDO9_PITTS.NAPSIMM_DEDO9_PITTS_R1;
      this.finger10Pitts = this.answers.PITTS.NAPSIMM_DEDO10_PITTS.NAPSIMM_DEDO10_PITTS_R1;
      this.finger1Onicodistrofia =
        answers.ONICODISTROFIA.NAPSIMM_DEDO1_ONICODISTROFIA.NAPSIMM_DEDO1_ONICODISTROFIA_R1;
      this.finger2Onicodistrofia =
        answers.ONICODISTROFIA.NAPSIMM_DEDO2_ONICODISTROFIA.NAPSIMM_DEDO2_ONICODISTROFIA_R1;
      this.finger3Onicodistrofia =
        answers.ONICODISTROFIA.NAPSIMM_DEDO3_ONICODISTROFIA.NAPSIMM_DEDO3_ONICODISTROFIA_R1;
      this.finger4Onicodistrofia =
        answers.ONICODISTROFIA.NAPSIMM_DEDO4_ONICODISTROFIA.NAPSIMM_DEDO4_ONICODISTROFIA_R1;
      this.finger5Onicodistrofia =
        answers.ONICODISTROFIA.NAPSIMM_DEDO5_ONICODISTROFIA.NAPSIMM_DEDO5_ONICODISTROFIA_R1;
      this.finger6Onicodistrofia =
        answers.ONICODISTROFIA.NAPSIMM_DEDO6_ONICODISTROFIA.NAPSIMM_DEDO6_ONICODISTROFIA_R1;
      this.finger7Onicodistrofia =
        answers.ONICODISTROFIA.NAPSIMM_DEDO7_ONICODISTROFIA.NAPSIMM_DEDO7_ONICODISTROFIA_R1;
      this.finger8Onicodistrofia =
        answers.ONICODISTROFIA.NAPSIMM_DEDO8_ONICODISTROFIA.NAPSIMM_DEDO8_ONICODISTROFIA_R1;
      this.finger9Onicodistrofia =
        answers.ONICODISTROFIA.NAPSIMM_DEDO9_ONICODISTROFIA.NAPSIMM_DEDO9_ONICODISTROFIA_R1;
      this.finger10Onicodistrofia =
        answers.ONICODISTROFIA.NAPSIMM_DEDO10_ONICODISTROFIA.NAPSIMM_DEDO10_ONICODISTROFIA_R1;
      this.finger1Leuconiquia = this.answers.LEUCONIQUIA.NAPSIMM_DEDO1_LEUCONIQUIA.NAPSIMM_DEDO1_LEUCONIQUIA_R1;
      this.finger2Leuconiquia = this.answers.LEUCONIQUIA.NAPSIMM_DEDO2_LEUCONIQUIA.NAPSIMM_DEDO2_LEUCONIQUIA_R1;
      this.finger3Leuconiquia = this.answers.LEUCONIQUIA.NAPSIMM_DEDO3_LEUCONIQUIA.NAPSIMM_DEDO3_LEUCONIQUIA_R1;
      this.finger4Leuconiquia = this.answers.LEUCONIQUIA.NAPSIMM_DEDO4_LEUCONIQUIA.NAPSIMM_DEDO4_LEUCONIQUIA_R1;
      this.finger5Leuconiquia = this.answers.LEUCONIQUIA.NAPSIMM_DEDO5_LEUCONIQUIA.NAPSIMM_DEDO5_LEUCONIQUIA_R1;
      this.finger6Leuconiquia = this.answers.LEUCONIQUIA.NAPSIMM_DEDO6_LEUCONIQUIA.NAPSIMM_DEDO6_LEUCONIQUIA_R1;
      this.finger7Leuconiquia = this.answers.LEUCONIQUIA.NAPSIMM_DEDO7_LEUCONIQUIA.NAPSIMM_DEDO7_LEUCONIQUIA_R1;
      this.finger8Leuconiquia = this.answers.LEUCONIQUIA.NAPSIMM_DEDO8_LEUCONIQUIA.NAPSIMM_DEDO8_LEUCONIQUIA_R1;
      this.finger9Leuconiquia = this.answers.LEUCONIQUIA.NAPSIMM_DEDO9_LEUCONIQUIA.NAPSIMM_DEDO9_LEUCONIQUIA_R1;
      this.finger10Leuconiquia = this.answers.LEUCONIQUIA.NAPSIMM_DEDO10_LEUCONIQUIA.NAPSIMM_DEDO10_LEUCONIQUIA_R1;
      this.finger1Hemorragias = this.answers.HEMORRAGIAS.NAPSIMM_DEDO1_HEMORRAGIAS.NAPSIMM_DEDO1_HEMORRAGIAS_R1;
      this.finger2Hemorragias = this.answers.HEMORRAGIAS.NAPSIMM_DEDO2_HEMORRAGIAS.NAPSIMM_DEDO2_HEMORRAGIAS_R1;
      this.finger3Hemorragias = this.answers.HEMORRAGIAS.NAPSIMM_DEDO3_HEMORRAGIAS.NAPSIMM_DEDO3_HEMORRAGIAS_R1;
      this.finger4Hemorragias = this.answers.HEMORRAGIAS.NAPSIMM_DEDO4_HEMORRAGIAS.NAPSIMM_DEDO4_HEMORRAGIAS_R1;
      this.finger5Hemorragias = this.answers.HEMORRAGIAS.NAPSIMM_DEDO5_HEMORRAGIAS.NAPSIMM_DEDO5_HEMORRAGIAS_R1;
      this.finger6Hemorragias = this.answers.HEMORRAGIAS.NAPSIMM_DEDO6_HEMORRAGIAS.NAPSIMM_DEDO6_HEMORRAGIAS_R1;
      this.finger7Hemorragias = this.answers.HEMORRAGIAS.NAPSIMM_DEDO7_HEMORRAGIAS.NAPSIMM_DEDO7_HEMORRAGIAS_R1;
      this.finger8Hemorragias = this.answers.HEMORRAGIAS.NAPSIMM_DEDO8_HEMORRAGIAS.NAPSIMM_DEDO8_HEMORRAGIAS_R1;
      this.finger9Hemorragias = this.answers.HEMORRAGIAS.NAPSIMM_DEDO9_HEMORRAGIAS.NAPSIMM_DEDO9_HEMORRAGIAS_R1;
      this.finger10Hemorragias = this.answers.HEMORRAGIAS.NAPSIMM_DEDO10_HEMORRAGIAS.NAPSIMM_DEDO10_HEMORRAGIAS_R1;
      this.finger1Hiperqueratosis = this.answers.HIPERQUERATOSIS.NAPSIMM_DEDO1_HIPERQUERATOSIS.NAPSIMM_DEDO1_HIPERQUERATOSIS_R1;
      this.finger2Hiperqueratosis = this.answers.HIPERQUERATOSIS.NAPSIMM_DEDO2_HIPERQUERATOSIS.NAPSIMM_DEDO2_HIPERQUERATOSIS_R1;
      this.finger3Hiperqueratosis = this.answers.HIPERQUERATOSIS.NAPSIMM_DEDO3_HIPERQUERATOSIS.NAPSIMM_DEDO3_HIPERQUERATOSIS_R1;
      this.finger4Hiperqueratosis = this.answers.HIPERQUERATOSIS.NAPSIMM_DEDO4_HIPERQUERATOSIS.NAPSIMM_DEDO4_HIPERQUERATOSIS_R1;
      this.finger5Hiperqueratosis = this.answers.HIPERQUERATOSIS.NAPSIMM_DEDO5_HIPERQUERATOSIS.NAPSIMM_DEDO5_HIPERQUERATOSIS_R1;
      this.finger6Hiperqueratosis = this.answers.HIPERQUERATOSIS.NAPSIMM_DEDO6_HIPERQUERATOSIS.NAPSIMM_DEDO6_HIPERQUERATOSIS_R1;
      this.finger7Hiperqueratosis = this.answers.HIPERQUERATOSIS.NAPSIMM_DEDO7_HIPERQUERATOSIS.NAPSIMM_DEDO7_HIPERQUERATOSIS_R1;
      this.finger8Hiperqueratosis = this.answers.HIPERQUERATOSIS.NAPSIMM_DEDO8_HIPERQUERATOSIS.NAPSIMM_DEDO8_HIPERQUERATOSIS_R1;
      this.finger9Hiperqueratosis = this.answers.HIPERQUERATOSIS.NAPSIMM_DEDO9_HIPERQUERATOSIS.NAPSIMM_DEDO9_HIPERQUERATOSIS_R1;
      this.finger10Hiperqueratosis = this.answers.HIPERQUERATOSIS.NAPSIMM_DEDO10_HIPERQUERATOSIS.NAPSIMM_DEDO10_HIPERQUERATOSIS_R1;
      this.finger1Manchas = this.answers.MANCHAS.NAPSIMM_DEDO1_MANCHAS.NAPSIMM_DEDO1_MANCHAS_R1;
      this.finger2Manchas = this.answers.MANCHAS.NAPSIMM_DEDO2_MANCHAS.NAPSIMM_DEDO2_MANCHAS_R1;
      this.finger3Manchas = this.answers.MANCHAS.NAPSIMM_DEDO3_MANCHAS.NAPSIMM_DEDO3_MANCHAS_R1;
      this.finger4Manchas = this.answers.MANCHAS.NAPSIMM_DEDO4_MANCHAS.NAPSIMM_DEDO4_MANCHAS_R1;
      this.finger5Manchas = this.answers.MANCHAS.NAPSIMM_DEDO5_MANCHAS.NAPSIMM_DEDO5_MANCHAS_R1;
      this.finger6Manchas = this.answers.MANCHAS.NAPSIMM_DEDO6_MANCHAS.NAPSIMM_DEDO6_MANCHAS_R1;
      this.finger7Manchas = this.answers.MANCHAS.NAPSIMM_DEDO7_MANCHAS.NAPSIMM_DEDO7_MANCHAS_R1;
      this.finger8Manchas = this.answers.MANCHAS.NAPSIMM_DEDO8_MANCHAS.NAPSIMM_DEDO8_MANCHAS_R1;
      this.finger9Manchas = this.answers.MANCHAS.NAPSIMM_DEDO9_MANCHAS.NAPSIMM_DEDO9_MANCHAS_R1;
      this.finger10Manchas = this.answers.MANCHAS.NAPSIMM_DEDO10_MANCHAS.NAPSIMM_DEDO10_MANCHAS_R1;
    },
    restoreForm() {
      this.finger1Onicolisis = this.restoreData.finger1Onicolisis;
      this.finger2Onicolisis = this.restoreData.finger2Onicolisis;
      this.finger3Onicolisis = this.restoreData.finger3Onicolisis;
      this.finger4Onicolisis = this.restoreData.finger4Onicolisis;
      this.finger5Onicolisis = this.restoreData.finger5Onicolisis;
      this.finger6Onicolisis = this.restoreData.finger6Onicolisis;
      this.finger7Onicolisis = this.restoreData.finger7Onicolisis;
      this.finger8Onicolisis = this.restoreData.finger8Onicolisis;
      this.finger9Onicolisis = this.restoreData.finger9Onicolisis;
      this.finger10Onicolisis = this.restoreData.finger10Onicolisis;
      this.finger1Pitts = this.restoreData.finger1Pitts;
      this.finger2Pitts = this.restoreData.finger2Pitts;
      this.finger3Pitts = this.restoreData.finger3Pitts;
      this.finger4Pitts = this.restoreData.finger4Pitts;
      this.finger5Pitts = this.restoreData.finger5Pitts;
      this.finger6Pitts = this.restoreData.finger6Pitts;
      this.finger7Pitts = this.restoreData.finger7Pitts;
      this.finger8Pitts = this.restoreData.finger8Pitts;
      this.finger9Pitts = this.restoreData.finger9Pitts;
      this.finger10Pitts = this.restoreData.finger10Pitts;
      this.finger1Onicodistrofia = this.restoreData.finger1Onicodistrofia;
      this.finger2Onicodistrofia = this.restoreData.finger2Onicodistrofia;
      this.finger3Onicodistrofia = this.restoreData.finger3Onicodistrofia;
      this.finger4Onicodistrofia = this.restoreData.finger4Onicodistrofia;
      this.finger5Onicodistrofia = this.restoreData.finger5Onicodistrofia;
      this.finger6Onicodistrofia = this.restoreData.finger6Onicodistrofia;
      this.finger7Onicodistrofia = this.restoreData.finger7Onicodistrofia;
      this.finger8Onicodistrofia = this.restoreData.finger8Onicodistrofia;
      this.finger9Onicodistrofia = this.restoreData.finger9Onicodistrofia;
      this.finger10Onicodistrofia = this.restoreData.finger10Onicodistrofia;
      this.finger1Leuconiquia = this.restoreData.finger1Leuconiquia;
      this.finger2Leuconiquia = this.restoreData.finger2Leuconiquia;
      this.finger3Leuconiquia = this.restoreData.finger3Leuconiquia;
      this.finger4Leuconiquia = this.restoreData.finger4Leuconiquia;
      this.finger5Leuconiquia = this.restoreData.finger5Leuconiquia;
      this.finger6Leuconiquia = this.restoreData.finger6Leuconiquia;
      this.finger7Leuconiquia = this.restoreData.finger7Leuconiquia;
      this.finger8Leuconiquia = this.restoreData.finger8Leuconiquia;
      this.finger9Leuconiquia = this.restoreData.finger9Leuconiquia;
      this.finger10Leuconiquia = this.restoreData.finger10Leuconiquia;
      this.finger1Hemorragias = this.restoreData.finger1Hemorragias;
      this.finger2Hemorragias = this.restoreData.finger2Hemorragias;
      this.finger3Hemorragias = this.restoreData.finger3Hemorragias;
      this.finger4Hemorragias = this.restoreData.finger4Hemorragias;
      this.finger5Hemorragias = this.restoreData.finger5Hemorragias;
      this.finger6Hemorragias = this.restoreData.finger6Hemorragias;
      this.finger7Hemorragias = this.restoreData.finger7Hemorragias;
      this.finger8Hemorragias = this.restoreData.finger8Hemorragias;
      this.finger9Hemorragias = this.restoreData.finger9Hemorragias;
      this.finger10Hemorragias = this.restoreData.finger10Hemorragias;
      this.finger1Hiperqueratosis = this.restoreData.finger1Hiperqueratosis;
      this.finger2Hiperqueratosis = this.restoreData.finger2Hiperqueratosis;
      this.finger3Hiperqueratosis = this.restoreData.finger3Hiperqueratosis;
      this.finger4Hiperqueratosis = this.restoreData.finger4Hiperqueratosis;
      this.finger5Hiperqueratosis = this.restoreData.finger5Hiperqueratosis;
      this.finger6Hiperqueratosis = this.restoreData.finger6Hiperqueratosis;
      this.finger7Hiperqueratosis = this.restoreData.finger7Hiperqueratosis;
      this.finger8Hiperqueratosis = this.restoreData.finger8Hiperqueratosis;
      this.finger9Hiperqueratosis = this.restoreData.finger9Hiperqueratosis;
      this.finger10Hiperqueratosis = this.restoreData.finger10Hiperqueratosis;
      this.finger1Manchas = this.restoreData.finger1Manchas;
      this.finger2Manchas = this.restoreData.finger2Manchas;
      this.finger3Manchas = this.restoreData.finger3Manchas;
      this.finger4Manchas = this.restoreData.finger4Manchas;
      this.finger5Manchas = this.restoreData.finger5Manchas;
      this.finger6Manchas = this.restoreData.finger6Manchas;
      this.finger7Manchas = this.restoreData.finger7Manchas;
      this.finger8Manchas = this.restoreData.finger8Manchas;
      this.finger9Manchas = this.restoreData.finger9Manchas;
      this.finger10Manchas = this.restoreData.finger10Manchas;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "NAPSI MM",
          route: "Napsimm",
        },
        survey: {
          finger1Onicolisis: this.finger1Onicolisis,
          finger2Onicolisis: this.finger2Onicolisis,
          finger3Onicolisis: this.finger3Onicolisis,
          finger4Onicolisis: this.finger4Onicolisis,
          finger5Onicolisis: this.finger5Onicolisis,
          finger6Onicolisis: this.finger6Onicolisis,
          finger7Onicolisis: this.finger7Onicolisis,
          finger8Onicolisis: this.finger8Onicolisis,
          finger9Onicolisis: this.finger9Onicolisis,
          finger10Onicolisis: this.finger10Onicolisis,
          finger1Pitts: this.finger1Pitts,
          finger2Pitts: this.finger2Pitts,
          finger3Pitts: this.finger3Pitts,
          finger4Pitts: this.finger4Pitts,
          finger5Pitts: this.finger5Pitts,
          finger6Pitts: this.finger6Pitts,
          finger7Pitts: this.finger7Pitts,
          finger8Pitts: this.finger8Pitts,
          finger9Pitts: this.finger9Pitts,
          finger10Pitts: this.finger10Pitts,
          finger1Onicodistrofia: this.finger1Onicodistrofia,
          finger2Onicodistrofia: this.finger2Onicodistrofia,
          finger3Onicodistrofia: this.finger3Onicodistrofia,
          finger4Onicodistrofia: this.finger4Onicodistrofia,
          finger5Onicodistrofia: this.finger5Onicodistrofia,
          finger6Onicodistrofia: this.finger6Onicodistrofia,
          finger7Onicodistrofia: this.finger7Onicodistrofia,
          finger8Onicodistrofia: this.finger8Onicodistrofia,
          finger9Onicodistrofia: this.finger9Onicodistrofia,
          finger10Onicodistrofia: this.finger10Onicodistrofia,
          finger1Leuconiquia: this.finger1Leuconiquia,
          finger2Leuconiquia: this.finger2Leuconiquia,
          finger3Leuconiquia: this.finger3Leuconiquia,
          finger4Leuconiquia: this.finger4Leuconiquia,
          finger5Leuconiquia: this.finger5Leuconiquia,
          finger6Leuconiquia: this.finger6Leuconiquia,
          finger7Leuconiquia: this.finger7Leuconiquia,
          finger8Leuconiquia: this.finger8Leuconiquia,
          finger9Leuconiquia: this.finger9Leuconiquia,
          finger10Leuconiquia: this.finger10Leuconiquia,
          finger1Hemorragias: this.finger1Hemorragias,
          finger2Hemorragias: this.finger2Hemorragias,
          finger3Hemorragias: this.finger3Hemorragias,
          finger4Hemorragias: this.finger4Hemorragias,
          finger5Hemorragias: this.finger5Hemorragias,
          finger6Hemorragias: this.finger6Hemorragias,
          finger7Hemorragias: this.finger7Hemorragias,
          finger8Hemorragias: this.finger8Hemorragias,
          finger9Hemorragias: this.finger9Hemorragias,
          finger10Hemorragias: this.finger10Hemorragias,
          finger1Hiperqueratosis: this.finger1Hiperqueratosis,
          finger2Hiperqueratosis: this.finger2Hiperqueratosis,
          finger3Hiperqueratosis: this.finger3Hiperqueratosis,
          finger4Hiperqueratosis: this.finger4Hiperqueratosis,
          finger5Hiperqueratosis: this.finger5Hiperqueratosis,
          finger6Hiperqueratosis: this.finger6Hiperqueratosis,
          finger7Hiperqueratosis: this.finger7Hiperqueratosis,
          finger8Hiperqueratosis: this.finger8Hiperqueratosis,
          finger9Hiperqueratosis: this.finger9Hiperqueratosis,
          finger10Hiperqueratosis: this.finger10Hiperqueratosis,
          finger1Manchas: this.finger1Manchas,
          finger2Manchas: this.finger2Manchas,
          finger3Manchas: this.finger3Manchas,
          finger4Manchas: this.finger4Manchas,
          finger5Manchas: this.finger5Manchas,
          finger6Manchas: this.finger6Manchas,
          finger7Manchas: this.finger7Manchas,
          finger8Manchas: this.finger8Manchas,
          finger9Manchas: this.finger9Manchas,
          finger10Manchas: this.finger10Manchas,
        },
      });
    },
    setAnswers() {
      let finger1Onicolisis = null;
      let finger2Onicolisis = null;
      let finger3Onicolisis = null;
      let finger4Onicolisis = null;
      let finger5Onicolisis = null;
      let finger6Onicolisis = null;
      let finger7Onicolisis = null;
      let finger8Onicolisis = null;
      let finger9Onicolisis = null;
      let finger10Onicolisis = null;
      let finger1Pitts = null;
      let finger2Pitts = null;
      let finger3Pitts = null;
      let finger4Pitts = null;
      let finger5Pitts = null;
      let finger6Pitts = null;
      let finger7Pitts = null;
      let finger8Pitts = null;
      let finger9Pitts = null;
      let finger10Pitts = null;
      let finger1Onicodistrofia = null;
      let finger2Onicodistrofia = null;
      let finger3Onicodistrofia = null;
      let finger4Onicodistrofia = null;
      let finger5Onicodistrofia = null;
      let finger6Onicodistrofia = null;
      let finger7Onicodistrofia = null;
      let finger8Onicodistrofia = null;
      let finger9Onicodistrofia = null;
      let finger10Onicodistrofia = null;
      let finger1Leuconiquia = null;
      let finger2Leuconiquia = null;
      let finger3Leuconiquia = null;
      let finger4Leuconiquia = null;
      let finger5Leuconiquia = null;
      let finger6Leuconiquia = null;
      let finger7Leuconiquia = null;
      let finger8Leuconiquia = null;
      let finger9Leuconiquia = null;
      let finger10Leuconiquia = null;
      let finger1Hemorragias = null;
      let finger2Hemorragias = null;
      let finger3Hemorragias = null;
      let finger4Hemorragias = null;
      let finger5Hemorragias = null;
      let finger6Hemorragias = null;
      let finger7Hemorragias = null;
      let finger8Hemorragias = null;
      let finger9Hemorragias = null;
      let finger10Hemorragias = null;
      let finger1Hiperqueratosis = null;
      let finger2Hiperqueratosis = null;
      let finger3Hiperqueratosis = null;
      let finger4Hiperqueratosis = null;
      let finger5Hiperqueratosis = null;
      let finger6Hiperqueratosis = null;
      let finger7Hiperqueratosis = null;
      let finger8Hiperqueratosis = null;
      let finger9Hiperqueratosis = null;
      let finger10Hiperqueratosis = null;
      let finger1Manchas = null;
      let finger2Manchas = null;
      let finger3Manchas = null;
      let finger4Manchas = null;
      let finger5Manchas = null;
      let finger6Manchas = null;
      let finger7Manchas = null;
      let finger8Manchas = null;
      let finger9Manchas = null;
      let finger10Manchas = null;

      this.finger1OnicolisisAnswers.forEach((element) => {
        if (element[1] == this.finger1Onicolisis) {
          finger1Onicolisis = {
            questionCode: this.questionOnicolisisKeys[0],
            answerCode: element[0],
            value: this.finger1Onicolisis,
          };
        }
      });

      this.finger2OnicolisisAnswers.forEach((element) => {
        if (element[1] == this.finger2Onicolisis) {
          finger2Onicolisis = {
            questionCode: this.questionOnicolisisKeys[1],
            answerCode: element[0],
            value: this.finger2Onicolisis,
          };
        }
      });

      this.finger3OnicolisisAnswers.forEach((element) => {
        if (element[1] == this.finger3Onicolisis) {
          finger3Onicolisis = {
            questionCode: this.questionOnicolisisKeys[2],
            answerCode: element[0],
            value: this.finger3Onicolisis,
          };
        }
      });

      this.finger4OnicolisisAnswers.forEach((element) => {
        if (element[1] == this.finger4Onicolisis) {
          finger4Onicolisis = {
            questionCode: this.questionOnicolisisKeys[3],
            answerCode: element[0],
            value: this.finger4Onicolisis,
          };
        }
      });

      this.finger5OnicolisisAnswers.forEach((element) => {
        if (element[1] == this.finger5Onicolisis) {
          finger5Onicolisis = {
            questionCode: this.questionOnicolisisKeys[4],
            answerCode: element[0],
            value: this.finger5Onicolisis,
          };
        }
      });

      this.finger6OnicolisisAnswers.forEach((element) => {
        if (element[1] == this.finger6Onicolisis) {
          finger6Onicolisis = {
            questionCode: this.questionOnicolisisKeys[5],
            answerCode: element[0],
            value: this.finger6Onicolisis,
          };
        }
      });

      this.finger7OnicolisisAnswers.forEach((element) => {
        if (element[1] == this.finger7Onicolisis) {
          finger7Onicolisis = {
            questionCode: this.questionOnicolisisKeys[6],
            answerCode: element[0],
            value: this.finger7Onicolisis,
          };
        }
      });

      this.finger8OnicolisisAnswers.forEach((element) => {
        if (element[1] == this.finger8Onicolisis) {
          finger8Onicolisis = {
            questionCode: this.questionOnicolisisKeys[7],
            answerCode: element[0],
            value: this.finger8Onicolisis,
          };
        }
      });

      this.finger9OnicolisisAnswers.forEach((element) => {
        if (element[1] == this.finger9Onicolisis) {
          finger9Onicolisis = {
            questionCode: this.questionOnicolisisKeys[8],
            answerCode: element[0],
            value: this.finger9Onicolisis,
          };
        }
      });

      this.finger10OnicolisisAnswers.forEach((element) => {
        if (element[1] == this.finger10Onicolisis) {
          finger10Onicolisis = {
            questionCode: this.questionOnicolisisKeys[9],
            answerCode: element[0],
            value: this.finger10Onicolisis,
          };
        }
      });

      /***************** */

      this.finger1PittsAnswers.forEach((element) => {
        if (element[1] == this.finger1Pitts) {
          finger1Pitts = {
            questionCode: this.questionPittsKeys[0],
            answerCode: element[0],
            value: this.finger1Pitts,
          };
        }
      });

      this.finger2PittsAnswers.forEach((element) => {
        if (element[1] == this.finger2Pitts) {
          finger2Pitts = {
            questionCode: this.questionPittsKeys[1],
            answerCode: element[0],
            value: this.finger2Pitts,
          };
        }
      });

      this.finger3PittsAnswers.forEach((element) => {
        if (element[1] == this.finger3Pitts) {
          finger3Pitts = {
            questionCode: this.questionPittsKeys[2],
            answerCode: element[0],
            value: this.finger3Pitts,
          };
        }
      });

      this.finger4PittsAnswers.forEach((element) => {
        if (element[1] == this.finger4Pitts) {
          finger4Pitts = {
            questionCode: this.questionPittsKeys[3],
            answerCode: element[0],
            value: this.finger4Pitts,
          };
        }
      });

      this.finger5PittsAnswers.forEach((element) => {
        if (element[1] == this.finger5Pitts) {
          finger5Pitts = {
            questionCode: this.questionPittsKeys[4],
            answerCode: element[0],
            value: this.finger5Pitts,
          };
        }
      });

      this.finger6PittsAnswers.forEach((element) => {
        if (element[1] == this.finger6Pitts) {
          finger6Pitts = {
            questionCode: this.questionPittsKeys[5],
            answerCode: element[0],
            value: this.finger6Pitts,
          };
        }
      });

      this.finger7PittsAnswers.forEach((element) => {
        if (element[1] == this.finger7Pitts) {
          finger7Pitts = {
            questionCode: this.questionPittsKeys[6],
            answerCode: element[0],
            value: this.finger7Pitts,
          };
        }
      });

      this.finger8PittsAnswers.forEach((element) => {
        if (element[1] == this.finger8Pitts) {
          finger8Pitts = {
            questionCode: this.questionPittsKeys[7],
            answerCode: element[0],
            value: this.finger8Pitts,
          };
        }
      });

      this.finger9PittsAnswers.forEach((element) => {
        if (element[1] == this.finger9Pitts) {
          finger9Pitts = {
            questionCode: this.questionPittsKeys[8],
            answerCode: element[0],
            value: this.finger9Pitts,
          };
        }
      });

      this.finger10PittsAnswers.forEach((element) => {
        if (element[1] == this.finger10Pitts) {
          finger10Pitts = {
            questionCode: this.questionPittsKeys[9],
            answerCode: element[0],
            value: this.finger10Pitts,
          };
        }
      });

      /************** */

      this.finger1OnicodistrofiaAnswers.forEach((element) => {
        if (element[1] == this.finger1Onicodistrofia) {
          finger1Onicodistrofia = {
            questionCode: this.questionOnicodistrofiaKeys[0],
            answerCode: element[0],
            value: this.finger1Onicodistrofia,
          };
        }
      });

      this.finger2OnicodistrofiaAnswers.forEach((element) => {
        if (element[1] == this.finger2Onicodistrofia) {
          finger2Onicodistrofia = {
            questionCode: this.questionOnicodistrofiaKeys[1],
            answerCode: element[0],
            value: this.finger2Onicodistrofia,
          };
        }
      });

      this.finger3OnicodistrofiaAnswers.forEach((element) => {
        if (element[1] == this.finger3Onicodistrofia) {
          finger3Onicodistrofia = {
            questionCode: this.questionOnicodistrofiaKeys[2],
            answerCode: element[0],
            value: this.finger3Onicodistrofia,
          };
        }
      });

      this.finger4OnicodistrofiaAnswers.forEach((element) => {
        if (element[1] == this.finger4Onicodistrofia) {
          finger4Onicodistrofia = {
            questionCode: this.questionOnicodistrofiaKeys[3],
            answerCode: element[0],
            value: this.finger4Onicodistrofia,
          };
        }
      });

      this.finger5OnicodistrofiaAnswers.forEach((element) => {
        if (element[1] == this.finger5Onicodistrofia) {
          finger5Onicodistrofia = {
            questionCode: this.questionOnicodistrofiaKeys[4],
            answerCode: element[0],
            value: this.finger5Onicodistrofia,
          };
        }
      });

      this.finger6OnicodistrofiaAnswers.forEach((element) => {
        if (element[1] == this.finger6Onicodistrofia) {
          finger6Onicodistrofia = {
            questionCode: this.questionOnicodistrofiaKeys[5],
            answerCode: element[0],
            value: this.finger6Onicodistrofia,
          };
        }
      });

      this.finger7OnicodistrofiaAnswers.forEach((element) => {
        if (element[1] == this.finger7Onicodistrofia) {
          finger7Onicodistrofia = {
            questionCode: this.questionOnicodistrofiaKeys[6],
            answerCode: element[0],
            value: this.finger7Onicodistrofia,
          };
        }
      });

      this.finger8OnicodistrofiaAnswers.forEach((element) => {
        if (element[1] == this.finger8Onicodistrofia) {
          finger8Onicodistrofia = {
            questionCode: this.questionOnicodistrofiaKeys[7],
            answerCode: element[0],
            value: this.finger8Onicodistrofia,
          };
        }
      });

      this.finger9OnicodistrofiaAnswers.forEach((element) => {
        if (element[1] == this.finger9Onicodistrofia) {
          finger9Onicodistrofia = {
            questionCode: this.questionOnicodistrofiaKeys[8],
            answerCode: element[0],
            value: this.finger9Onicodistrofia,
          };
        }
      });

      this.finger10OnicodistrofiaAnswers.forEach((element) => {
        if (element[1] == this.finger10Onicodistrofia) {
          finger10Onicodistrofia = {
            questionCode: this.questionOnicodistrofiaKeys[9],
            answerCode: element[0],
            value: this.finger10Onicodistrofia,
          };
        }
      });

      /*************** */

      this.finger1LeuconiquiaAnswers.forEach((element) => {
        if (element[1] == this.finger1Leuconiquia) {
          finger1Leuconiquia = {
            questionCode: this.questionLeuconiquiaKeys[0],
            answerCode: element[0],
            value: this.finger1Leuconiquia,
          };
        }
      });

      this.finger2LeuconiquiaAnswers.forEach((element) => {
        if (element[1] == this.finger2Leuconiquia) {
          finger2Leuconiquia = {
            questionCode: this.questionLeuconiquiaKeys[1],
            answerCode: element[0],
            value: this.finger2Leuconiquia,
          };
        }
      });

      this.finger3LeuconiquiaAnswers.forEach((element) => {
        if (element[1] == this.finger3Leuconiquia) {
          finger3Leuconiquia = {
            questionCode: this.questionLeuconiquiaKeys[2],
            answerCode: element[0],
            value: this.finger3Leuconiquia,
          };
        }
      });

      this.finger4LeuconiquiaAnswers.forEach((element) => {
        if (element[1] == this.finger4Leuconiquia) {
          finger4Leuconiquia = {
            questionCode: this.questionLeuconiquiaKeys[3],
            answerCode: element[0],
            value: this.finger4Leuconiquia,
          };
        }
      });

      this.finger5LeuconiquiaAnswers.forEach((element) => {
        if (element[1] == this.finger5Leuconiquia) {
          finger5Leuconiquia = {
            questionCode: this.questionLeuconiquiaKeys[4],
            answerCode: element[0],
            value: this.finger5Leuconiquia,
          };
        }
      });

      this.finger6LeuconiquiaAnswers.forEach((element) => {
        if (element[1] == this.finger6Leuconiquia) {
          finger6Leuconiquia = {
            questionCode: this.questionLeuconiquiaKeys[5],
            answerCode: element[0],
            value: this.finger6Leuconiquia,
          };
        }
      });

      this.finger7LeuconiquiaAnswers.forEach((element) => {
        if (element[1] == this.finger7Leuconiquia) {
          finger7Leuconiquia = {
            questionCode: this.questionLeuconiquiaKeys[6],
            answerCode: element[0],
            value: this.finger7Leuconiquia,
          };
        }
      });

      this.finger8LeuconiquiaAnswers.forEach((element) => {
        if (element[1] == this.finger8Leuconiquia) {
          finger8Leuconiquia = {
            questionCode: this.questionLeuconiquiaKeys[7],
            answerCode: element[0],
            value: this.finger8Leuconiquia,
          };
        }
      });

      this.finger9LeuconiquiaAnswers.forEach((element) => {
        if (element[1] == this.finger9Leuconiquia) {
          finger9Leuconiquia = {
            questionCode: this.questionLeuconiquiaKeys[8],
            answerCode: element[0],
            value: this.finger9Leuconiquia,
          };
        }
      });

      this.finger10LeuconiquiaAnswers.forEach((element) => {
        if (element[1] == this.finger10Leuconiquia) {
          finger10Leuconiquia = {
            questionCode: this.questionLeuconiquiaKeys[9],
            answerCode: element[0],
            value: this.finger10Leuconiquia,
          };
        }
      });

      /************* */

      this.finger1HemorragiasAnswers.forEach((element) => {
        if (element[1] == this.finger1Hemorragias) {
          finger1Hemorragias = {
            questionCode: this.questionHemorragiasKeys[0],
            answerCode: element[0],
            value: this.finger1Hemorragias,
          };
        }
      });

      this.finger2HemorragiasAnswers.forEach((element) => {
        if (element[1] == this.finger2Hemorragias) {
          finger2Hemorragias = {
            questionCode: this.questionHemorragiasKeys[1],
            answerCode: element[0],
            value: this.finger2Hemorragias,
          };
        }
      });

      this.finger3HemorragiasAnswers.forEach((element) => {
        if (element[1] == this.finger3Hemorragias) {
          finger3Hemorragias = {
            questionCode: this.questionHemorragiasKeys[2],
            answerCode: element[0],
            value: this.finger3Hemorragias,
          };
        }
      });

      this.finger4HemorragiasAnswers.forEach((element) => {
        if (element[1] == this.finger4Hemorragias) {
          finger4Hemorragias = {
            questionCode: this.questionHemorragiasKeys[3],
            answerCode: element[0],
            value: this.finger4Hemorragias,
          };
        }
      });

      this.finger5HemorragiasAnswers.forEach((element) => {
        if (element[1] == this.finger5Hemorragias) {
          finger5Hemorragias = {
            questionCode: this.questionHemorragiasKeys[4],
            answerCode: element[0],
            value: this.finger5Hemorragias,
          };
        }
      });

      this.finger6HemorragiasAnswers.forEach((element) => {
        if (element[1] == this.finger6Hemorragias) {
          finger6Hemorragias = {
            questionCode: this.questionHemorragiasKeys[5],
            answerCode: element[0],
            value: this.finger6Hemorragias,
          };
        }
      });

      this.finger7HemorragiasAnswers.forEach((element) => {
        if (element[1] == this.finger7Hemorragias) {
          finger7Hemorragias = {
            questionCode: this.questionHemorragiasKeys[6],
            answerCode: element[0],
            value: this.finger7Hemorragias,
          };
        }
      });

      this.finger8HemorragiasAnswers.forEach((element) => {
        if (element[1] == this.finger8Hemorragias) {
          finger8Hemorragias = {
            questionCode: this.questionHemorragiasKeys[7],
            answerCode: element[0],
            value: this.finger8Hemorragias,
          };
        }
      });

      this.finger9HemorragiasAnswers.forEach((element) => {
        if (element[1] == this.finger9Hemorragias) {
          finger9Hemorragias = {
            questionCode: this.questionHemorragiasKeys[8],
            answerCode: element[0],
            value: this.finger9Hemorragias,
          };
        }
      });

      this.finger10HemorragiasAnswers.forEach((element) => {
        if (element[1] == this.finger10Hemorragias) {
          finger10Hemorragias = {
            questionCode: this.questionHemorragiasKeys[9],
            answerCode: element[0],
            value: this.finger10Hemorragias,
          };
        }
      });

      /********************* */

      this.finger1HiperqueratosisAnswers.forEach((element) => {
        if (element[1] == this.finger1Hiperqueratosis) {
          finger1Hiperqueratosis = {
            questionCode: this.questionHiperqueratosisKeys[0],
            answerCode: element[0],
            value: this.finger1Hiperqueratosis,
          };
        }
      });

      this.finger2HiperqueratosisAnswers.forEach((element) => {
        if (element[1] == this.finger2Hiperqueratosis) {
          finger2Hiperqueratosis = {
            questionCode: this.questionHiperqueratosisKeys[1],
            answerCode: element[0],
            value: this.finger2Hiperqueratosis,
          };
        }
      });

      this.finger3HiperqueratosisAnswers.forEach((element) => {
        if (element[1] == this.finger3Hiperqueratosis) {
          finger3Hiperqueratosis = {
            questionCode: this.questionHiperqueratosisKeys[2],
            answerCode: element[0],
            value: this.finger3Hiperqueratosis,
          };
        }
      });

      this.finger4HiperqueratosisAnswers.forEach((element) => {
        if (element[1] == this.finger4Hiperqueratosis) {
          finger4Hiperqueratosis = {
            questionCode: this.questionHiperqueratosisKeys[3],
            answerCode: element[0],
            value: this.finger4Hiperqueratosis,
          };
        }
      });

      this.finger5HiperqueratosisAnswers.forEach((element) => {
        if (element[1] == this.finger5Hiperqueratosis) {
          finger5Hiperqueratosis = {
            questionCode: this.questionHiperqueratosisKeys[4],
            answerCode: element[0],
            value: this.finger5Hiperqueratosis,
          };
        }
      });

      this.finger6HiperqueratosisAnswers.forEach((element) => {
        if (element[1] == this.finger6Hiperqueratosis) {
          finger6Hiperqueratosis = {
            questionCode: this.questionHiperqueratosisKeys[5],
            answerCode: element[0],
            value: this.finger6Hiperqueratosis,
          };
        }
      });

      this.finger7HiperqueratosisAnswers.forEach((element) => {
        if (element[1] == this.finger7Hiperqueratosis) {
          finger7Hiperqueratosis = {
            questionCode: this.questionHiperqueratosisKeys[6],
            answerCode: element[0],
            value: this.finger7Hiperqueratosis,
          };
        }
      });

      this.finger8HiperqueratosisAnswers.forEach((element) => {
        if (element[1] == this.finger8Hiperqueratosis) {
          finger8Hiperqueratosis = {
            questionCode: this.questionHiperqueratosisKeys[7],
            answerCode: element[0],
            value: this.finger8Hiperqueratosis,
          };
        }
      });

      this.finger9HiperqueratosisAnswers.forEach((element) => {
        if (element[1] == this.finger9Hiperqueratosis) {
          finger9Hiperqueratosis = {
            questionCode: this.questionHiperqueratosisKeys[8],
            answerCode: element[0],
            value: this.finger9Hiperqueratosis,
          };
        }
      });

      this.finger10HiperqueratosisAnswers.forEach((element) => {
        if (element[1] == this.finger10Hiperqueratosis) {
          finger10Hiperqueratosis = {
            questionCode: this.questionHiperqueratosisKeys[9],
            answerCode: element[0],
            value: this.finger10Hiperqueratosis,
          };
        }
      });

      /********************** */

      this.finger1ManchasAnswers.forEach((element) => {
        if (element[1] == this.finger1Manchas) {
          finger1Manchas = {
            questionCode: this.questionManchasKeys[0],
            answerCode: element[0],
            value: this.finger1Manchas,
          };
        }
      });

      this.finger2ManchasAnswers.forEach((element) => {
        if (element[1] == this.finger2Manchas) {
          finger2Manchas = {
            questionCode: this.questionManchasKeys[1],
            answerCode: element[0],
            value: this.finger2Manchas,
          };
        }
      });

      this.finger3ManchasAnswers.forEach((element) => {
        if (element[1] == this.finger3Manchas) {
          finger3Manchas = {
            questionCode: this.questionManchasKeys[2],
            answerCode: element[0],
            value: this.finger3Manchas,
          };
        }
      });

      this.finger4ManchasAnswers.forEach((element) => {
        if (element[1] == this.finger4Manchas) {
          finger4Manchas = {
            questionCode: this.questionManchasKeys[3],
            answerCode: element[0],
            value: this.finger4Manchas,
          };
        }
      });

      this.finger5ManchasAnswers.forEach((element) => {
        if (element[1] == this.finger5Manchas) {
          finger5Manchas = {
            questionCode: this.questionManchasKeys[4],
            answerCode: element[0],
            value: this.finger5Manchas,
          };
        }
      });

      this.finger6ManchasAnswers.forEach((element) => {
        if (element[1] == this.finger6Manchas) {
          finger6Manchas = {
            questionCode: this.questionManchasKeys[5],
            answerCode: element[0],
            value: this.finger6Manchas,
          };
        }
      });

      this.finger7ManchasAnswers.forEach((element) => {
        if (element[1] == this.finger7Manchas) {
          finger7Manchas = {
            questionCode: this.questionManchasKeys[6],
            answerCode: element[0],
            value: this.finger7Manchas,
          };
        }
      });

      this.finger8ManchasAnswers.forEach((element) => {
        if (element[1] == this.finger8Manchas) {
          finger8Manchas = {
            questionCode: this.questionManchasKeys[7],
            answerCode: element[0],
            value: this.finger8Manchas,
          };
        }
      });

      this.finger9ManchasAnswers.forEach((element) => {
        if (element[1] == this.finger9Manchas) {
          finger9Manchas = {
            questionCode: this.questionManchasKeys[8],
            answerCode: element[0],
            value: this.finger9Manchas,
          };
        }
      });

      this.finger10ManchasAnswers.forEach((element) => {
        if (element[1] == this.finger10Manchas) {
          finger10Manchas = {
            questionCode: this.questionManchasKeys[9],
            answerCode: element[0],
            value: this.finger10Manchas,
          };
        }
      });

      return [
        finger1Onicolisis,
        finger2Onicolisis,
        finger3Onicolisis,
        finger4Onicolisis,
        finger5Onicolisis,
        finger6Onicolisis,
        finger7Onicolisis,
        finger8Onicolisis,
        finger9Onicolisis,
        finger10Onicolisis,
        finger1Pitts,
        finger2Pitts,
        finger3Pitts,
        finger4Pitts,
        finger5Pitts,
        finger6Pitts,
        finger7Pitts,
        finger8Pitts,
        finger9Pitts,
        finger10Pitts,
        finger1Onicodistrofia,
        finger2Onicodistrofia,
        finger3Onicodistrofia,
        finger4Onicodistrofia,
        finger5Onicodistrofia,
        finger6Onicodistrofia,
        finger7Onicodistrofia,
        finger8Onicodistrofia,
        finger9Onicodistrofia,
        finger10Onicodistrofia,
        finger1Leuconiquia,
        finger2Leuconiquia,
        finger3Leuconiquia,
        finger4Leuconiquia,
        finger5Leuconiquia,
        finger6Leuconiquia,
        finger7Leuconiquia,
        finger8Leuconiquia,
        finger9Leuconiquia,
        finger10Leuconiquia,
        finger1Hemorragias,
        finger2Hemorragias,
        finger3Hemorragias,
        finger4Hemorragias,
        finger5Hemorragias,
        finger6Hemorragias,
        finger7Hemorragias,
        finger8Hemorragias,
        finger9Hemorragias,
        finger10Hemorragias,
        finger1Hiperqueratosis,
        finger2Hiperqueratosis,
        finger3Hiperqueratosis,
        finger4Hiperqueratosis,
        finger5Hiperqueratosis,
        finger6Hiperqueratosis,
        finger7Hiperqueratosis,
        finger8Hiperqueratosis,
        finger9Hiperqueratosis,
        finger10Hiperqueratosis,
        finger1Manchas,
        finger2Manchas,
        finger3Manchas,
        finger4Manchas,
        finger5Manchas,
        finger6Manchas,
        finger7Manchas,
        finger8Manchas,
        finger9Manchas,
        finger10Manchas,
      ];
    },
    async save() {
      try {
         
          this.changeLoaderStatus(true);
          
          let data = {
            surveyCode: "NAPSIMM",
            result: String(this.score),
            appreciation: "",
            doctorId: String(this.userId),
            patientId: String(this.patient.document),
            detail: this.setAnswers(),
          };

          await this.$surveyService.saveSurvey(data);
          this.changeLoaderStatus(false);
          this.showToast(
            "Encuesta enviada satisfactoriamente.",
            "is-success",
            3000
          );
          this.scrollTop();
          this.cleanForms();
          this.clearTemporalSurveys();
          this.changeLoaderStatus(false);
        
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    validate() {
      this.save();
      //  if (!this.isIncompleted) {
      //   this.save();
      // } else {
      //   this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      // }
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "NAPSIMM_DEDO1_ONICOLISIS") {
          this.finger1Onicolisis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO2_ONICOLISIS") {
          this.finger2Onicolisis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO3_ONICOLISIS") {
          this.finger3Onicolisis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO4_ONICOLISIS") {
          this.finger4Onicolisis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO5_ONICOLISIS") {
          this.finger5Onicolisis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO6_ONICOLISIS") {
          this.finger6Onicolisis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO7_ONICOLISIS") {
          this.finger7Onicolisis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO8_ONICOLISIS") {
          this.finger8Onicolisis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO9_ONICOLISIS") {
          this.finger9Onicolisis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO10_ONICOLISIS") {
          this.finger10Onicolisis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO1_PITTS") {
          this.finger1Pitts = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO2_PITTS") {
          this.finger2Pitts = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO3_PITTS") {
          this.finger3Pitts = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO4_PITTS") {
          this.finger4Pitts = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO5_PITTS") {
          this.finger5Pitts = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO6_PITTS") {
          this.finger6Pitts = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO7_PITTS") {
          this.finger7Pitts = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO8_PITTS") {
          this.finger8Pitts = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO9_PITTS") {
          this.finger9Pitts = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO10_PITTS") {
          this.finger10Pitts = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO1_ONICODISTROFIA") {
          this.finger1Onicodistrofia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO2_ONICODISTROFIA") {
          this.finger2Onicodistrofia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO3_ONICODISTROFIA") {
          this.finger3Onicodistrofia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO4_ONICODISTROFIA") {
          this.finger4Onicodistrofia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO5_ONICODISTROFIA") {
          this.finger5Onicodistrofia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO6_ONICODISTROFIA") {
          this.finger6Onicodistrofia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO7_ONICODISTROFIA") {
          this.finger7Onicodistrofia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO8_ONICODISTROFIA") {
          this.finger8Onicodistrofia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO9_ONICODISTROFIA") {
          this.finger9Onicodistrofia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO10_ONICODISTROFIA") {
          this.finger10Onicodistrofia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO1_LEUCONIQUIA") {
          this.finger1Leuconiquia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO2_LEUCONIQUIA") {
          this.finger2Leuconiquia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO3_LEUCONIQUIA") {
          this.finger3Leuconiquia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO4_LEUCONIQUIA") {
          this.finger4Leuconiquia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO5_LEUCONIQUIA") {
          this.finger5Leuconiquia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO6_LEUCONIQUIA") {
          this.finger6Leuconiquia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO7_LEUCONIQUIA") {
          this.finger7Leuconiquia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO8_LEUCONIQUIA") {
          this.finger8Leuconiquia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO9_LEUCONIQUIA") {
          this.finger9Leuconiquia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO10_LEUCONIQUIA") {
          this.finger10Leuconiquia = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO1_HEMORRAGIAS") {
          this.finger1Hemorragias = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO2_HEMORRAGIAS") {
          this.finger2Hemorragias = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO3_HEMORRAGIAS") {
          this.finger3Hemorragias = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO4_HEMORRAGIAS") {
          this.finger4Hemorragias = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO5_HEMORRAGIAS") {
          this.finger5Hemorragias = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO6_HEMORRAGIAS") {
          this.finger6Hemorragias = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO7_HEMORRAGIAS") {
          this.finger7Hemorragias = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO8_HEMORRAGIAS") {
          this.finger8Hemorragias = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO9_HEMORRAGIAS") {
          this.finger9Hemorragias = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO10_HEMORRAGIAS") {
          this.finger10Hemorragias = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO1_HIPERQUERATOSIS") {
          this.finger1Hiperqueratosis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO2_HIPERQUERATOSIS") {
          this.finger2Hiperqueratosis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO3_HIPERQUERATOSIS") {
          this.finger3Hiperqueratosis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO4_HIPERQUERATOSIS") {
          this.finger4Hiperqueratosis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO5_HIPERQUERATOSIS") {
          this.finger5Hiperqueratosis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO6_HIPERQUERATOSIS") {
          this.finger6Hiperqueratosis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO7_HIPERQUERATOSIS") {
          this.finger7Hiperqueratosis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO8_HIPERQUERATOSIS") {
          this.finger8Hiperqueratosis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO9_HIPERQUERATOSIS") {
          this.finger9Hiperqueratosis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO10_HIPERQUERATOSIS") {
          this.finger10Hiperqueratosis = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO1_MANCHAS") {
          this.finger1Manchas = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO2_MANCHAS") {
          this.finger2Manchas = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO3_MANCHAS") {
          this.finger3Manchas = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO4_MANCHAS") {
          this.finger4Manchas = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO5_MANCHAS") {
          this.finger5Manchas = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO6_MANCHAS") {
          this.finger6Manchas = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO7_MANCHAS") {
          this.finger7Manchas = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO8_MANCHAS") {
          this.finger8Manchas = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO9_MANCHAS") {
          this.finger9Manchas = survey.value;
        }

        if (survey.question.code == "NAPSIMM_DEDO10_MANCHAS") {
          this.finger10Manchas = survey.value;
        }
      });
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  background-color: transparent;
}
.table-container {
  overflow: auto;
}
</style>
